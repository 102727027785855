import { message } from "antd";
import classNames from "classnames";
import { FC, Key, Ref, useEffect, useRef } from "react";
import {
  getTeachersByPaginationApi,
  getTeachersMenuApi,
} from "../../api/teacher";
import CommonFilterTablePagination, {
  ITableSelectedData,
} from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import { useDialog } from "../../hooks/useDialog";
import Allocation, { IAllocationRef } from "../Allocation/Allocation";
// import FilterOperation from './FilterOperation'
import styles from "./Teachers.module.scss";

const Teachers: FC = () => {
  const { dialogs, openDialog, closeDialog } = useDialog();

  const allocationRef = useRef<any>()

  const operationFuncs = {
    AssignCourse: (tableData: ITableSelectedData) => {
      // 打开弹框allocation
      openDialog({
        title: "Assign Course",
        width: 900,
        content: (
          <Allocation
            ref={allocationRef}
            api="/teachers/courses/assign/info"
            target_id={tableData.selected[0] as string}
          />
        ),
        async onOk(done: Function) {
            if (allocationRef.current) {
                try {
                    const res = await allocationRef.current.submit()
                    done();
                    message.success(res.data.detail)
                    tableData.refreshList()
                } catch (error) {
                    message.error('error!')
                }
            }
        },
      });
    },
  };

  useEffect(() => {
  }, [])

  return (
    <>
      <div  className={classNames({
        [styles.page]:true,
        ['common-filter-table-pagination-page']: true
      })}>
        <CommonFilterTablePagination
          getSearchPromise={getTeachersMenuApi}
          getListPromise={getTeachersByPaginationApi}
          rowKey="teacher_id"
          operationFuncs={operationFuncs}
          // operation={<FilterOperation />}
        ></CommonFilterTablePagination>
      </div>
    </>
  );
};

export default Teachers;
