import { nanoid } from "nanoid";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../store";
import {
  closeAllDialog,
  closeDialog,
  updateDialog,
  DialogStateType,
  IDialog,
  openDialog,
} from "../store/dialogReducer";

export const dialogOptionMap: Record<string, IDialog> = {};

export const useDialog = () => {
  const dialogState = useSelector<StateType, DialogStateType>(
    (state) => state.dialog
  );
  const dispatch = useDispatch();
  const update = (dialog: Partial<IDialog>) => {
    if (!dialog.id) return;
    dialogOptionMap[dialog.id] = {
      ...dialogOptionMap[dialog.id],
      ...dialog,
    };
    dispatch(updateDialog(dialog));
  };
  const open = (dialog: IDialog) => {
    dialog._status = true;
    if (!dialog.id) {
      dialog.id = nanoid();
    }
    dialogOptionMap[dialog.id] = dialog;
    dispatch(
      openDialog({
        id: dialog.id,
        title: dialog.title,
        _status: dialog._status,
        width: dialog.width,
        height: dialog.height,
        hideFooter: dialog.hideFooter,
      })
    );
  };
  const close = (id?: string) => {
    const dialogs = dialogState.dialogs;
    if (!dialogs.length) return;
    if (!id) {
      id = dialogs[dialogs.length - 1].id;
    }
    if (id) {
      delete dialogOptionMap[id];
      dispatch(closeDialog(id || ""));
    }
  };
  const closeAll = () => {
    const dialogs = dialogState.dialogs;
    if (!dialogs.length) return;
    dispatch(closeAllDialog());
  };
  return {
    openDialog: open,
    closeDialog: close,
    closeAllDialog: closeAll,
    dialogs: dialogState.dialogs,
    updateDialog: update,
    // openDialog,
    // closeDialog,
    // dialogs,
    // count
  };
};

export const useDialogRef = () => {
  const dialog = useDialog()
  const dialogRef = useRef<typeof dialog>()
  useEffect(() => {
    dialogRef.current = dialog
  }, [dialog.dialogs])
  return dialogRef
}