import { FC, useEffect, useState } from "react";
import { Layout, Menu, Space, MenuProps } from "antd";
import styles from "./SideMenu.module.scss";
// import logoImg from "../../assets/layout/Logo.svg";
import logoImg from "../../assets/login/logo@.png";
import { MenuItem, getLatestMenus, menuMap } from "../../router/menu";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  filterPermissionMenus,
  findKeyPathFromPathName,
  findPathFromMenuKeyPath as findMenuFromMenuKeyPath,
  loopMenus,
} from "../../router/utils";
import { cloneDeep } from "lodash-es";
import { useDispatch } from "react-redux";
import { addMenu } from "../../store/menuReducer";
import { nanoid } from "nanoid";
import { useApp } from "../../context/app-context";

const { Sider } = Layout;

const SideMenu: FC = () => {
  const t = useTranslation();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const app = useApp();

  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);

  const [items, setItems] = useState<Array<any>>([]);
  useEffect(() => {
    const originItems = filterPermissionMenus(cloneDeep(getLatestMenus()));
    setItems(originItems);
    let keyPath = findKeyPathFromPathName(location.pathname);
    setSelectedKeys(keyPath);
  }, []);

  const onItemClick = ({ keyPath }: any) => {
    let nameKey = "";
    if (typeof keyPath === "string") {
      nameKey = keyPath;
    } else if (Array.isArray(keyPath) && keyPath[0]) {
      nameKey = keyPath[0];
    }
    console.log(menuMap)
    const router = menuMap[nameKey];
    setSelectedKeys([nameKey]);
    const permissionMap = app.getUserPermissionMap();
    const permission = permissionMap[nameKey];
    if (permission) {
      const componentType = permission.component?.type;
      let path = "";
      if (componentType) {
        if (componentType === "calendar") {
          path = `/CalendarPage/${permission.name}`;
        } else if (componentType === "pagination") {
          path = `/PaginationPage/${permission.name}`;
        }
        nav(path);
        dispatch(
          addMenu({
            title: permission.title,
            path,
            id: nanoid(5),
          })
        );
        return;
      }
    }
    if (router) {
      nav(router.path);
      dispatch(
        addMenu({
          title: router.title,
          path: router.path,
          id: nanoid(5),
        })
      );
    }
    // const menu = findMenuFromMenuKeyPath(keyPath as string[]);
    // if (menu) {
    //   nav(menu.fullPath);
    //   dispatch(
    //     addMenu({
    //       title: menu.title,
    //       path: menu.fullPath,
    //       id: nanoid(5),
    //     })
    //   );
    // }
  };

  return (
    <Sider
      className={styles.sideMenu}
      theme="light"
      style={{ backgroundColor: "#1C85BF" }}
      // style={{ backgroundColor: "#1c85bf" }}
    >
      <Space align="center" direction="vertical" style={{ width: "100%" }}>
        <img className={styles.logo} src={logoImg} alt="" />
      </Space>
      <Menu
        className={styles.menu}
        mode="inline"
        selectedKeys={selectedKeys}
        items={items}
        onClick={onItemClick}
      ></Menu>
    </Sider>
  );
};

export default SideMenu;
