import ApiList from "../common-filter-table-pagination/ApiList";
import DefaultItem from "../common-filter-table-pagination/DefaultItem";
import { TypeMap as FilterTypeMap } from "../common-filter-table-pagination/FilterItem";
import Keyword from "../common-filter-table-pagination/Keyword";
import { ICommonFormInfoItem } from "./types";

export const TypeMap: Record<string, any> = {
  ...FilterTypeMap,
  'str': Keyword,
  'dict_list': ApiList
}

// const TypeMap: Record<string, any> = {
//   [ICommonFormItemType.Input]: Keyword,
//   [ICommonFormItemType.Number]: Int,
//   [ICommonFormItemType.Date]: DateFilter,
//   [ICommonFormItemType.Enum]: ApiList,
//   [ICommonFormItemType.Null]: Null,
//   [ICommonFormItemType.Time]: TimeFilter,
//   [ICommonFormItemType.ApiList]: ApiList,
// };

const CommonFormItem = (props: ICommonFormInfoItem) => {
  const Component = TypeMap[props.type] || DefaultItem;
  return <Component {...props}></Component>;
};

export default CommonFormItem;
