import { Form, Input } from "antd";
import { FC } from "react";
import { ICommonFilterTableSearch } from "../../api/types";
import classNames from "classnames";

const Keyword: FC<ICommonFilterTableSearch> = (
  props: ICommonFilterTableSearch
) => {
  const className = classNames({
    "no-label": !props.prefix,
  });
  return (
    <Form.Item
      style={{
        width: props.width === 0 ? "initial" : (props.width || 200) + "px",
      }}
      rules={[{
        required: props.required,
        message: 'Please Enter'
      }]}
      label={props.prefix || " "}
      className={className}
      name={props.propKey}
    >
      <Input placeholder={props.display} />
    </Form.Item>
  );
};

export default Keyword;
