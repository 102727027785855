import { Button, Col, Form, FormInstance, Row } from "antd";
import { cloneDeep } from "lodash-es";
import React, { useImperativeHandle, useState } from "react";
import CommonFormItem from "./CommonFormItem";
import { ICommonFormInfoConfig } from "./types";

export interface ICommonFormProps {
  config: ICommonFormInfoConfig;
}

export interface ICommonFormRef {
  validateFields: FormInstance["validateFields"];
  getFormData: () => Record<string, any>;
  getFieldsValue: () => Record<string, any>;
}

const CommonFormWithTwoColumn = React.forwardRef(
  (props: ICommonFormProps, ref) => {
    useImperativeHandle(ref, () => ({
      validateFields: () => {
        return form.validateFields();
      },
      getFormData: () => {
        return formData;
      },
      getFieldsValue: () => {
        return form.getFieldsValue();
      },
      updateFormData: (formData: Record<string, any>) => {
        form.setFieldsValue(formData)
        setFormData((state) => {
          return {
            ...state,
            ...formData
          }
        })
      }
    }));

    const [form] = Form.useForm();
    const { optionalItems, requiredItems } = props.config;

    const [formData, setFormData] = useState<Record<string, any>>({});

    const handleFormItemChange = (newuValue: string) => {};
    const handleValuesChange = (values: any) => {
      const deleteUndefined = cloneDeep(values)
      for (let key in deleteUndefined) {
        if (deleteUndefined[key] === undefined) {
          delete deleteUndefined[key]
        }
      }
      setFormData((state) => {
        return {
          ...state,
          ...deleteUndefined,
        };
      });
    };

    return (
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelWrap={true}
        onValuesChange={handleValuesChange}
      >
        {process.env.NODE_ENV === "development" && JSON.stringify(formData)}
        <Row>
          <Col span={11}>
            {requiredItems.map((item, index) => (
              <CommonFormItem
                {...item}
                key={index + ""}
                propKey={item.key}
                filterBy={formData}
                width="100%"
                required={true}
              ></CommonFormItem>
            ))}
          </Col>
          <Col span={11} offset={1}>
            {optionalItems.map((item, index) => (
              <CommonFormItem
                {...item}
                key={index + ""}
                propKey={item.key}
                filterBy={formData}
                width="100%"
              ></CommonFormItem>
            ))}
          </Col>
        </Row>
      </Form>
    );
  }
);

export default CommonFormWithTwoColumn;
