import { FC } from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./Login.module.scss";
const { Text } = Typography;

const Footer: FC = () => {
  const { t } = useTranslation();
  return <Text className={styles.footer}>{t("Login.footer")}</Text>;
};

export default Footer;
