import { FC } from "react";
import { Outlet } from "react-router";
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Layout } from "antd";
import styles from './MainLayout.module.scss'
const { Content } = Layout;

const MainContent: FC = () => {
  return (
    // <TransitionGroup>
    //   <CSSTransition classNames="fade" key={location.pathname} timeout={500}>
    <Content className={'main-content'} style={{ margin: "15px", padding: '12px', backgroundColor: '#fff', overflow: 'auto' }}>
      <Outlet />
    </Content>
    //   </CSSTransition>
    // </TransitionGroup>
  );
};

export default MainContent;
