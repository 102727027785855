import {
  ICommonFormConfig,
  ICommonFormItemType,
} from "../../../components/common-form/types";
import { genderOptions, newStudentGradeOptions } from "./statics";

export const registerNewStudentFormConfig: ICommonFormConfig = {
  items: [
    {
      label: "Intrinsic School",
      name: "school_id",
      required: true,
      type: ICommonFormItemType.ApiList,
      list_api: "/schools/list",
      transformOption: {
        value: "school_id",
        label: "school_name",
      },
    },
    {
      label: "Birthday",
      name: "birthday",
      type: ICommonFormItemType.Date,
    },
    {
      label: "Login Name",
      name: "login_name",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "Email",
      name: "email",
      type: ICommonFormItemType.Input,
    },
    {
      label: "First Name",
      name: "first_name",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "Address Room",
      name: "address_room",
      type: ICommonFormItemType.Input,
    },
    {
      label: "Last Name",
      name: "last_name",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "Floor",
      name: "address_floor",
      type: ICommonFormItemType.Input,
    },
    {
      label: "Nick Name",
      name: "nickname",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "Building",
      name: "address_building",
      type: ICommonFormItemType.Input,
    },
    {
      label: "Student School",
      name: "school",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "Street/Estate",
      name: "address_street_estate",
      type: ICommonFormItemType.Input,
    },
    {
      label: "Student Grade",
      name: "student_grade",
      type: ICommonFormItemType.Enum,
      required: true,
      list: newStudentGradeOptions.map((option) => option.value),
    },
    {
      label: "Hobbies",
      name: "hobbies",
      type: ICommonFormItemType.Input,
    },
    {
      label: "Gender",
      name: "gender",
      type: ICommonFormItemType.Enum,
      required: true,
      list: genderOptions.map((option) => option.value),
    },
    {
      label: "Strengths",
      name: "strengths",
      type: ICommonFormItemType.Input,
    },
    {
      label: "Mobile",
      name: "mobile",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "Weaknesses",
      name: "weaknesses",
      type: ICommonFormItemType.Input,
    },
    {
      label: "District",
      name: "address_district",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "",
      name: "",
      type: ICommonFormItemType.Null,
    },
    {
      label: "City",
      name: "address_city",
      required: true,
      type: ICommonFormItemType.Input,
    },
  ],
};
