import { request } from "../utils/request";
import { AxiosResponse } from "axios";
import {
  IAssignInfoRes,
  ICommonFilterTablePaginationApi,
  ICommonFilterTablePaginationReq,
  ICommonFilterTableSearchApi,
} from "./types";

export const getCourseAssignInfo = (
  target_id: string
): Promise<AxiosResponse<IAssignInfoRes>> => {
  return request.get("/teachers/courses/assign/info", {
    params: {
      target_id: target_id,
    },
  });
};

export const getTeachersMenuApi: ICommonFilterTableSearchApi = () => {
  return request.get("/teachers/pagination/menu");
};

export const getTeachersByPaginationApi: ICommonFilterTablePaginationApi = (
  params: ICommonFilterTablePaginationReq
) => {
  return request.post("/teachers/pagination", params);
};
