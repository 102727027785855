import { request } from "../utils/request";
import { AxiosResponse } from "axios";

export interface IStudentCourseInfoRes {
  student_info: IStudentCourseInfo;
  enrolled_list: Array<IStudentCourseEnrolled>;
  waiting_list: Array<IStudentCourseWaiting>;
  schools: Array<IStudentCourseSchool>;
}

export interface IStudentCourseInfo {
  student_id: string;
  name: string;
  school: string;
  grade: string;
}

export interface IStudentCourseEnrolled {
  course_sale_id: string;
  course: string;
  stage: string;
  payment_due_date: string;
  tip: boolean;
}

export interface IStudentCourseWaiting {
  waiting_id: string;
  type: string;
  course: string;
}

export interface IStudentCourseSchool {
  school_name: string;
  school_id: string;
}

export const getStudentCourseInfo = (params: {
  student_id: string;
  qr_code: string;
  new_student_grade: string;
}): Promise<AxiosResponse<IStudentCourseInfoRes>> => {
  return request.get("/students/courses/info", {
    params,
  });
};

export interface IStudentCourseMasterRecommended {
  course_master_id: string;
  name: string;
  info: string;
  can_apply: boolean;
  can_appoint: boolean;
}
export interface IStudentCoursesMasterRes {
  recommended: IStudentCourseMasterRecommended[];
  others: IStudentCourseMasterRecommended[];
}
export const getStudentCoursesMaster = (params: {
  student_id: string;
  school_id: string;
  new_student_grade: string;
}): Promise<AxiosResponse<IStudentCoursesMasterRes>> => {
  return request.get("/students/courses/master", {
    params,
  });
};

export interface ISaveStudentParams {
  school_id: string;
  login_name: string;
  first_name: string;
  last_name: string;
  nickname: string;
  school: string;
  student_grade: string;
  gender: string;
  mobile: string;
  address_district: string;
  address_city: string;
  birthday: string;
  email: string;
  address_room: string;
  address_floor: string;
  address_building: string;
  address_street_estate: string;
  hobbies: string;
  strengths: string;
  weaknesses: string;
}
export const saveStudentApi = (
  student: ISaveStudentParams
): Promise<
  AxiosResponse<{
    student_id: string;
    detail: string;
  }>
> => {
  return request.post("/students", student);
};

export interface IStuentCourseMasterStage {
  can_apply: boolean;
  can_appoint: boolean;
  course_id: string;
  lesson_duration: number;
  lesson_num: number;
  name: string;
  stage: string;
  student_level: string;
  tip: boolean;
}

export interface IGetStudentCourseMasterStagesRes {
  selected: string;
  stages: Array<IStuentCourseMasterStage>;
}

export interface IGetStudentCourseMasterStagesParams {
  course_master_id: string;
  student_id: string;
  new_student_grade: string;
}
export const getStudentCourseMasterStagesApi = (
  params: IGetStudentCourseMasterStagesParams
): Promise<AxiosResponse<IGetStudentCourseMasterStagesRes>> => {
  return request.get("/students/courses/master/stages", {
    params,
  });
};

export interface IStuentCourseMasterStagePackage {
  can_appoint: boolean;
  can_sign_up: boolean;
  charge: string;
  lesson_per_week: number;
  price: string;
  name: string;
  sale_package_id: string;
}

export interface IGetStudentCourseMasterStagePackageRes {
  selected: string;
  sale_packages: Array<IStuentCourseMasterStagePackage>;
}

export interface IGetStudentCourseMasterStageSalePackageParams {
  course_id: string;
  student_id: string;
  new_student_grade: string;
}

export const getStudentCourseMasterStageSalePackagesApi = (
  params: IGetStudentCourseMasterStageSalePackageParams
): Promise<AxiosResponse<IGetStudentCourseMasterStagePackageRes>> => {
  return request.get("/students/courses/master/stages/sale_packages", {
    params,
  });
};

export const postStudentCourseWaitingApi = (params: {
  student_id: string;
  school_id: string;
  sale_package_id: string;
}): Promise<AxiosResponse<{
  detail: string
  waiting_id: string
}>> => {
  return request.post("/students/courses/waiting", params);
};
