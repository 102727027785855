export enum ICommonFormItemType {
  Input = "input",
  Null = "null",
  Date = "date",
  Enum = "enum",
  Number = "number",
  Time = "time",
  ApiList = "apilist",
}

export interface ICommonFormItem {
  label: string;
  name: string;
  required?: boolean;
  type: ICommonFormItemType;
  list_api?: string;
  list?: Array<string>;
  deps?: Array<string>;
  // list_res_transform?: (item: Record<string, any>) => Record<string, any>;
  transformOption?: {
    value: string;
    label: string;
  };
}

export interface ICommonFormConfig {
  items: ICommonFormItem[];
}
