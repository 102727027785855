import { request } from "../utils/request";
import { AxiosResponse } from "axios";

export interface ISaveAppointmentParams {
  date: string;
  time: string;
  school_id: string;
  course_master_id: string;
  name: string;
  age: string;
  mobile: string;
  remarks: string;
}
export const saveAppointmentApi = (params: ISaveAppointmentParams) => {
  return request.post("/appointments", params);
};
