import {
  Button,
  message,
  Modal,
  Row,
  Space,
  Table,
  TableColumnProps,
} from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router";
import {
  getStudentCourseMasterStageSalePackagesApi,
  getStudentCourseMasterStagesApi,
  IGetStudentCourseMasterStagePackageRes,
  IGetStudentCourseMasterStagesRes,
  IStuentCourseMasterStage,
  IStuentCourseMasterStagePackage,
  postStudentCourseWaitingApi,
} from "../../../api/student";
import { useDialog } from "../../../hooks/useDialog";
import { useMenuTag } from "../../../hooks/useMenuTag";
import styles from "../Signup.module.scss";

export interface IApplyStudentCourseStagePackageProps {
  course_id: string;
  student_id: string;
  new_student_grade: string;
  onAppoint: Function;
  school_id: string;
}

const ApplyStudentCourseStagePackage = (
  props: IApplyStudentCourseStagePackageProps
) => {
  const dialog = useDialog();
  const { goRouteAndAddTag } = useMenuTag();
  const nav = useNavigate();
  const [studentCourseStage, setStudentCourseState] =
    useState<IGetStudentCourseMasterStagePackageRes>({
      selected: "",
      sale_packages: [],
    });

  const getData = async () => {
    try {
      let res = await getStudentCourseMasterStageSalePackagesApi({
        student_id: props.student_id,
        new_student_grade: props.new_student_grade,
        course_id: props.course_id,
      });
      setStudentCourseState(res.data);
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    }
  };

  const columns: ColumnsType<IStuentCourseMasterStagePackage> = [
    {
      title: "Sale Package",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Lessons per Week",
      key: "lesson_per_week",
      dataIndex: "lesson_per_week",
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "Charge",
      key: "charge",
      dataIndex: "charge",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, item) => {
        return (
          item.can_appoint && (
            <Button type="primary" size="small" onClick={() => handleAppoint()}>
              Appoint
            </Button>
          ),
          item.can_sign_up ? (
            <Button
              type="primary"
              size="small"
              onClick={() => handleSignup(item)}
            >
              Sign Up
            </Button>
          ) : (
            <Button disabled type="default" size="small">
              Not Available
            </Button>
          )
        );
      },
    },
  ];

  const handleAppoint = () => {
    props.onAppoint();
  };

  const handleSignup = async (item: IStuentCourseMasterStagePackage) => {
    try {
      let res = await postStudentCourseWaitingApi({
        student_id: props.student_id,
        school_id: props.school_id,
        sale_package_id: item.sale_package_id,
      });
      Modal.confirm({
        title: "Are you going to assign class now?",
        cancelText: "No",
        okText: "YES",
        onCancel(...args) {
          // 关闭当前页面
          dialog.closeAllDialog()
        },
        onOk(...args) {
          // 跳转
          // assign-class/:waitingId
          // nav('/assign-class/' + res.data.waiting_id)
          dialog.closeAllDialog()
          goRouteAndAddTag('/assign-class/' + res.data.waiting_id, 'Assign Class')
          // nav('/assign-class/' + res.data.waiting_id)
        },
      });
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className={classNames({
        [styles.page]: true,
        ["signup_apply_student_course_stage"]: true,
      })}
    >
      <Row>
        <div className={styles.blockTitle}>{studentCourseStage.selected}</div>
        <div className={styles.blockTitle}>Sale Packages</div>
        <Table
          columns={columns}
          dataSource={studentCourseStage.sale_packages}
          rowKey="sale_package_id"
        ></Table>
      </Row>
    </div>
  );
};

export default ApplyStudentCourseStagePackage;
