import { FC, useEffect, useState } from "react";
import { Select } from "antd";
import { ICommonOption } from "./types";
import { request } from "../../utils/request";
import { getCommonOptionsByApi } from "../../api/common";
import { cloneDeep, isEqual } from "lodash-es";
interface CommonSelectProps {
  placeholder?: string;
  options?: ICommonOption[] | Record<string, any>;
  labelKey?: string;
  valueKey?: string;
  api?: string;
  onChange?: (newVal: string | undefined) => void;
  onValueChange?: (newVal: string | undefined) => void;
  allowClear?: boolean;
  deps?: Record<string, any>;
  multiple?: boolean;
  width?: string;
  showSearch?: boolean;
  className?: string;
  defaultValue?: string | number
}

const CommonSelect: FC<CommonSelectProps> = (props: CommonSelectProps) => {
  const [options, setOptions] = useState<ICommonOption[]>(
    (props.options || []).map((option: Record<string, any>) => {
      return {
        value: option[props.valueKey || "value"],
        label: option[props.labelKey || "label"],
      };
    })
  );

  const [value, setValue] = useState<string | undefined>(undefined);

  const handleChange = (newValue: string | undefined) => {
    setValue(newValue);
    props.onChange && props.onChange(newValue);
    props.onValueChange && props.onValueChange(newValue);
  };

  const handleFilterOption: any = (
    inputValue: string,
    option: Record<string, string>
  ) => {
    if (!inputValue) return;
    return (option.label || option.children)
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  };

  const requesetApi = (params?: Record<string, any>) => {
    let api = props.api;
    if (!api) return;
    getCommonOptionsByApi(api, params).then((res) => {
      const datas = res.data as Array<Record<string, any>>;
      setOptions(
        datas.map((data) => {
          return {
            value: data[props.valueKey || "value"],
            label: data[props.labelKey || "label"],
          };
        })
      );
    });
  };
  let [lastDep, setLastDep] = useState<Record<string, any>>(
    cloneDeep(props.deps || {})
  );

  const isFullObject = (obj: Record<string, any>) => {
    const values = Object.values(obj);
    if (
      values.includes("") ||
      values.includes(null) ||
      values.includes(undefined)
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!props.deps || !Object.keys(props.deps).length) return;
    if (isEqual(props.deps, lastDep)) {
      return;
    }
    if (isFullObject(props.deps)) {
      if (value) {
        // 清除选中的值
        setValue(undefined);
      }
      requesetApi(props.deps);
    } else {
      // 缺少依赖数据，清空选项框
      if (options.length) {
        setOptions([]);
      }
      if (value) {
        // 清除选中的值
        setValue(undefined);
      }
    }
    // 记录最后变更的值
    setLastDep(cloneDeep(props.deps));
  }, [props.deps]);

  useEffect(() => {
    if (props.options) {
      setOptions(
        props.options.map((option: Record<string, any>) => ({
          value: option[props.valueKey || "value"],
          label: option[props.labelKey || "label"],
        }))
      );
    }
  }, [props.options]);

  useEffect(() => {
    if (props.api) {
      if (props.deps && Object.keys(props.deps)) {
        return;
      }
      requesetApi();
    }
    if (props.defaultValue) {
      setValue(props.defaultValue as any)
    }
  }, []);

  return (
    <Select
      className={props.className}
      style={{
        width: props.width,
      }}
      value={value}
      placeholder={props.placeholder}
      allowClear={props.allowClear}
      filterOption={handleFilterOption}
      mode={props.multiple ? "multiple" : undefined}
      onChange={handleChange}
      maxTagCount="responsive"
      showSearch={props.showSearch}
    >
      {options.map((option) => {
        return <Select.Option key={option.value}>{option.label}</Select.Option>;
      })}
    </Select>
  );
};

export default CommonSelect;
