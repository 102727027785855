import { Button } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useMenuTag } from "../../hooks/useMenuTag";

const FilerOperation: FC = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { goRouteAndAddTag } = useMenuTag()
  const handleNew = () => {
    // nav('/classes/new')
    goRouteAndAddTag('/classes/new', 'New Classes')
  };
  return (
    <>
      <Button type="primary" onClick={handleNew}>
        {t("Class.new")}
      </Button>
    </>
  );
};

export default FilerOperation;
