import { message } from "antd";
import { nanoid } from "nanoid";
import { Ref } from "react";
import { ICommonCalendarEventRes, ICommonFormInfoRes } from "../../api/common";
import { ICommonFilterOperator } from "../../api/types";
import AssignInfoForm from "./AssignInfoForm";
import CrudInfoForm from "./CrudInfoForm";

export const executeCrudEvent = (
  operator: ICommonFilterOperator | ICommonCalendarEventRes,
  params: {
    crudDialogRef: any;
    crudInfoFormRef: any;
    selectedRowKeys: any;
    tableData?: any;
  }
) => {

  if (!operator.component) return;
  const type = operator.component.type;
  const dialogId = nanoid();
  if (type === "crud") {
    params.crudDialogRef.current?.openDialog({
      id: dialogId,
      title: operator.title,
      content: (
        <CrudInfoForm
          ref={params.crudInfoFormRef}
          pagination_ids={params.selectedRowKeys as string[]}
          info_api={operator.component.info_api}
          inited={(formInfo: ICommonFormInfoRes) => {
            params.crudDialogRef.current?.updateDialog({
              id: dialogId,
              title: formInfo.heading,
            });
          }}
        />
      ),
      width: 860,
      onOk: async () => {
        try {
          let data: any = await params.crudInfoFormRef.current.updateInfoData();
          if (data) {
            message.success(data.detail);
            // 刷新页面
            params.tableData?.refreshList();
            params.crudDialogRef?.current?.closeDialog();
          }
        } catch (e: any) {
          console.error(e);
          message.error(e?.response?.data?.detail || "Error");
        }
      },
    });
  }
};


export const executeRedirectUrlEvent = (operator: ICommonFilterOperator | ICommonCalendarEventRes,
  params: {
    crudDialogRef: any;
    crudInfoFormRef: any;
    selectedRowKeys: any;
    tableData?: any;
    redirect: (redirect_url: string) => void
  }) => {
  const redirectOperator = operator as ICommonFilterOperator
  if (redirectOperator.redirect_url) {
    let url = redirectOperator.redirect_url.replace("{pagination_id}", params.selectedRowKeys[0])

    if (redirectOperator.open_new_tab) {
      window.open(url)
    } else {
      // 打开一个新tab
      params.redirect(url)
    }
    return
  }
}