import { Form, DatePicker } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import { FC } from "react";
import { ICommonFilterTableSearch } from "../../api/types";

const DateFilter: FC<
  ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
> = (
  props: ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
) => {
  const className = classNames({
    "no-label": !props.prefix,
  });
  return (
    <Form.Item
      style={{
        width: props.width === 0 ? "initial" : (props.width || 200) + "px",
      }}
      rules={[{
        required: props.required
      }]}
      label={props.prefix}
      className={className}
      name={props.propKey}
    >
      <InnerDate {...props}></InnerDate>
    </Form.Item>
  );
};

const InnerDate: FC<
  ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
> = (
  props: ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
) => {
  const handleChange = (newDate: any) => {
    if (props.onChange) {
      props.onChange(newDate.format("YYYY-MM-DD"));
    }
  };
  return (
    <>
      <DatePicker
        style={{ width: "100%" }}
        placeholder={props.display}
        value={
          props.filterBy[props.propKey]
            ? dayjs(props.filterBy[props.propKey])
            : undefined
        }
        onChange={handleChange}
      />
    </>
  );
};

export default DateFilter;
