import { Form } from "antd";
import { FC } from "react";

import { ICommonFilterTableSearchType } from "../../api/types";

export interface IDefaultItemProps {
  type: ICommonFilterTableSearchType;
}

const DefaultItem: FC<IDefaultItemProps> = (props: IDefaultItemProps) => {
  return <div>type: {props.type}</div>;
};
export default DefaultItem;
