import { message } from "antd";
import classNames from "classnames";
import { FC, Key, Ref, useRef } from "react";
import { getAuthoritiesByPaginationApi, getAuthorityMenuApi } from "../../api/authority";
import CommonFilterTablePagination, {
  ITableSelectedData,
} from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import { useDialog } from "../../hooks/useDialog";
import Allocation from "../Allocation/Allocation";
import styles from "./Authorities.module.scss";

const Authorities: FC = () => {
  const { dialogs, openDialog, closeDialog } = useDialog();

  const allocationRef = useRef<any>()

  const operationFuncs = {
    AuthorityAssignRole: (tableData: ITableSelectedData) => {
        openDialog({
            title: "Assign Course",
            width: 900,
            content: (
              <Allocation
                ref={allocationRef}
                api="/authorities/roles/assign/info"
                target_id={tableData.selected[0] as string}
              />
            ),
            async onOk(done: Function) {
                if (allocationRef.current) {
                    try {
                        const res = await allocationRef.current.submit()
                        done();
                        message.success(res.data.detail)
                        tableData.refreshList()
                    } catch (error) {
                        message.error('error!')
                    }
                }
            },
          });
    },
    AuthorityAssignService: (tableData: ITableSelectedData) => {
        openDialog({
            title: "Assign Service",
            width: 900,
            content: (
              <Allocation
                ref={allocationRef}
                api="/authorities/services/assign/info"
                target_id={tableData.selected[0] as string}
              />
            ),
            async onOk(done: Function) {
                if (allocationRef.current) {
                    try {
                        const res = await allocationRef.current.submit()
                        done();
                        message.success(res.data.detail)
                        tableData.refreshList()
                    } catch (error) {
                        message.error('error!')
                    }
                }
            },
          });
    }
  };

  return (
    <>
      <div className={classNames({
        [styles.page]:true,
        ['common-filter-table-pagination-page']: true
      })}>
        <CommonFilterTablePagination
          getSearchPromise={getAuthorityMenuApi}
          getListPromise={getAuthoritiesByPaginationApi}
          rowKey="authority_id"
          operationFuncs={operationFuncs}
          // operation={<FilterOperation />}
        ></CommonFilterTablePagination>
      </div>
    </>
  );
};

export default Authorities;
