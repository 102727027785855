import classNames from "classnames";
import CommonCalendarInfo from "../../../components/common-calendar-info/CommonCalendarInfo";
import styles from "./SchoolCalendarInfo.module.scss";

const SchoolCalendarInfo = () => {
  return (
    <div
      className={classNames({
        [styles.page]: true,
        ["common-calendar-info-page"]: true,
      })}
    >
      <CommonCalendarInfo infoApi="/schools/calendar/info" dataApi="/schools/calendar"></CommonCalendarInfo>
    </div>
  );
};

export default SchoolCalendarInfo;
