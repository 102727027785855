import { Button, Col, InputNumber, message, Row, Space } from "antd";
import React, { useEffect, useImperativeHandle, useState } from "react";
import {
  assignClassesApi,
  calculateCoursePriceApi,
  confirmPaymentCourseApi,
  IAssignClassesRes,
} from "../../api/classes";
import { useDialog } from "../../hooks/useDialog";
import styles from "../Signup/Signup.module.scss";
import classNames from "classnames";
import "./PriceInfo.scss";
import ConfirmPayment from "./ConfirmPayment";
import { useApp } from "../../context/app-context";

export interface IPriceInfo {
  waitingId: string;
  classesIds: string[];
}

const PriceInfo = (props: IPriceInfo) => {
  const dialog = useDialog();
  const app = useApp();

  const [priceInfos, setProceInfos] = useState<IAssignClassesRes["price_info"]>(
    []
  );
  const [discount, setDiscount] = useState<number | null>(null);

  const assignClasses = async () => {
    try {
      app.setLoading(true);
      let res = await assignClassesApi({
        waiting_id: props.waitingId,
        class_ids: props.classesIds,
      });
      if (res.data.detail) {
        message.success(res.data.detail);
        dialog.closeDialog();
      } else {
        setProceInfos(res.data.price_info);
        setDiscount(res.data.discount);
      }
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
      dialog.closeDialog();
    } finally {
      app.setLoading(false);
    }
  };

  const handleCalculate = async () => {
    try {
      app.setLoading(true);
      let res = await calculateCoursePriceApi({
        waiting_id: props.waitingId,
        class_ids: props.classesIds,
        discount: discount == null ? null : discount,
      });
      setProceInfos(res.data.price_info);
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    } finally {
      app.setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      app.setLoading(true);
      let res = await confirmPaymentCourseApi({
        discount: discount == null ? null : discount,
        waiting_id: props.waitingId,
        class_ids: props.classesIds,
      });
      //   res.data.transaction_id
      dialog.openDialog({
        title: "Tranaction Info",
        content: <ConfirmPayment transaction_id={res.data.transaction_id} />,
        hideFooter: true,
      });
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    } finally {
      app.setLoading(false);
    }
  };

  useEffect(() => {
    assignClasses();
  }, []);

  //   useImperativeHandle(ref, () => {});
  return (
    <div
      className={classNames({
        ["assign-class-priceinfo-page"]: true,
        [styles.page]: true,
      })}
    >
      <Row>
        <div
          className={classNames({
            [styles.blockTitle]: true,
            "search-title": true,
          })}
        >
          Price Info
          <div className="right-search-wrapper">
            Discount: &nbsp;
            <Space>
              <InputNumber value={discount} onChange={(v) => setDiscount(v)} />
              <Button type="primary" onClick={handleCalculate}>
                Calculate
              </Button>
            </Space>
          </div>
        </div>
        {priceInfos.map((priceInfo, index) => {
          return (
            <Col span={24} key={index}>
              <div className={styles.blockItem}>
                <div className={styles.title}>{priceInfo.title}</div>
                <div className={styles.content}>{priceInfo.content}</div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row justify={"end"} style={{ paddingTop: "10px" }}>
        <Button type="primary" onClick={handleConfirm}>
          Confirm and Make Payment
        </Button>
      </Row>
    </div>
  );
};

export default PriceInfo;
