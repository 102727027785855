import { FC, useEffect, useRef, useState, createElement } from "react";
import { render, createPortal } from "react-dom";
import ReactDOM from "react-dom/client";
import classNames from "classnames";
import styles from "./PaginationPage.module.scss";
import { AppProvider, useApp } from "../../context/app-context";
import { useParams } from "react-router";
import { IPaginationComponent } from "../../store/userReducer";
import CommonFilterTablePagination from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import {
  getCommonFilterTableByPaginationApi,
  getCommonFilterTableMenuApi,
} from "../../api/common";
import { ICommonFilterTablePaginationReq } from "../../api/types";
import { ConfigProvider, Spin } from "antd";
import { useWatch } from "../../hooks/useWatch";
import { Provider } from "react-redux";
import store from "../../store";
import enUS from "antd/es/locale/en_US";
import zhCN from "antd/es/locale/zh_CN";
import { getUser } from "../../utils/user";

const locales: Record<string, any> = {
  cn: zhCN,
  en: enUS,
};

const elMap: Record<string, HTMLDivElement> = {};

const PaginationPage: FC = () => {
  const { name } = useParams() as { name: string };
  const app = useApp();

  const lang = (getUser()?.lang || "cn").toLowerCase();

  //   const [componentInfo, setComponentInfo] = useState({
  //     menu_api: "",
  //     pagination_api: "",
  //     pagination_id: "",
  //   });

  const [allComponentInfo, setAllComponentInfo] = useState<
    Record<
      string,
      {
        menu_api: string;
        pagination_api: string;
        pagination_id: string;
      }
    >
  >({});

  const [paginationMap, setgPainationMap] = useState<
    Record<string, JSX.Element>
  >({});

  useEffect(() => {
    const permissionMap = app.getUserPermissionMap();
    const permission = permissionMap[name];
    if (!permission) {
      window.alert("permission 缺少匹配信息:" + name);
      return;
    }
    if (!permission.component) {
      window.alert("permission 缺少component匹配信息:" + name);
      return;
    }
    const component: IPaginationComponent = permission.component as any;
    setAllComponentInfo({
      [name]: {
        menu_api: component.menu_api,
        pagination_api: component.pagination_api,
        pagination_id: "pagination_id",
      },
    });
    const paginationCom = createPagePagination(name, {
      menu_api: component.menu_api,
      pagination_api: component.pagination_api,
      pagination_id: "pagination_id",
    });
    if (!paginationMap[name]) {
      paginationMap[name] = paginationCom;
    }

    setTimeout(() => {
        const target = document.querySelector('#pagination-' + name) as HTMLDivElement
        if (pageRef.current && target) {
            if (pageRef.current.firstChild) {
                document.querySelector('#paginationMap')?.appendChild(pageRef.current.firstChild)
            }
            pageRef.current.appendChild(target)
        }
    }, 500)
  }, [name]);

  //   useEffect(() => {
  //     const permissionMap = app.getUserPermissionMap();
  //     const permission = permissionMap[name];
  //     if (!permission) {
  //       window.alert("permission 缺少匹配信息:" + name);
  //       return;
  //     }
  //     if (!permission.component) {
  //       window.alert("permission 缺少component匹配信息:" + name);
  //       return;
  //     }
  //     const component: IPaginationComponent = permission.component as any;
  //     setComponentInfo({
  //       menu_api: component.menu_api,
  //       pagination_api: component.pagination_api,
  //       pagination_id: "pagination_id",
  //     });
  //   }, [name]);

  const [refreshPageTrigger, setRefreshPageTrigger] = useState(false);

  const triggerRefrshPage = () => {
    setRefreshPageTrigger(true);
    setTimeout(() => {
      setRefreshPageTrigger(false);
    }, 1000);
  };

  const pageRef = useRef<HTMLDivElement | null>(null);

  // useWatch(componentInfo.pagination_api, (newVal, oldVal) => {
  //   if (newVal && oldVal) {
  //     // 全部都有的时候，说明是
  //   }
  // });

  //   const [elMap, setElMap] = useState<Record<string, HTMLElement>>({});
  const createPagePagination = (
    name: string,
    componentInfo: {
      menu_api: string;
      pagination_api: string;
      pagination_id: string;
    }
  ) => {
    return (
      <CommonFilterTablePagination
        key={name}
        id={'pagination-' + name}
        getSearchPromise={() =>
          getCommonFilterTableMenuApi(componentInfo.menu_api)
        }
        getListPromise={(params: ICommonFilterTablePaginationReq) =>
          getCommonFilterTableByPaginationApi(
            params,
            componentInfo.pagination_api
          )
        }
        rowKey={"pagination_id"}
      ></CommonFilterTablePagination>
    );
  };

  return (
    <>
      <div
        className={classNames({
          [styles.page]: true,
          ["common-filter-table-pagination-page"]: true,
        })}
      >
        {refreshPageTrigger ? <Spin></Spin> : <div ref={pageRef} className="wrapper"></div>}
      </div>
      <div style={{ display: "none" }} id="paginationMap">
        {Object.keys(paginationMap).map((item) => {
          return paginationMap[item]
        })}
      </div>
    </>
  );
};

export default PaginationPage;
