import { request } from "../utils/request";
import { AxiosResponse } from "axios";
import {
  ICommonFilterTablePaginationApi,
  ICommonFilterTablePaginationReq,
  ICommonFilterTableSearchApi,
} from "./types";

export const getClassMenuApi: ICommonFilterTableSearchApi = (waiting_id?: string) => {
  return request.get("/classes/pagination/menu", {
    params: {
        waiting_id
    }
  });
};

export const getClassesByPaginationApi: ICommonFilterTablePaginationApi = (
  params: ICommonFilterTablePaginationReq,
  waiting_id?: string
) => {
  return request.post("/classes/pagination?waiting_id=" + waiting_id, params);
};
