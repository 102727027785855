import { FC, useEffect, useState } from "react";
import { Layout, Space, Typography, Dropdown, MenuProps } from "antd";
import styles from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import userImg from "../../assets/login/zhanghao.svg";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { setToken } from "../../utils/token";
import { getUser, setUser } from "../../utils/user";
import { useDispatch } from "react-redux";
import { resetMenu } from "../../store/menuReducer";

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

const MainHeader: FC = () => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({
    nickname: "",
    avatar: "",
  });

  const menus: MenuProps["items"] = [
    {
      key: "logout",
      label: t("Main.logout"),
    },
  ];
  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") {
      // 退出登錄
      setToken("");
      setUser(undefined);
      dispatch(resetMenu([]))
      localStorage.setItem('menus', '')
      nav("/login");
    }
  };

  useEffect(() => {
    const user = getUser();
    if (!user) {
      nav("/login");
      return;
    }
    setUserInfo((userInfo) => {
      return {
        ...userInfo,
        nickname: user.nickname,
      };
    });
  }, []);

  return (
    <Header className={styles.header}>
      <div className={styles.left}>
        <Text className={styles.title}>{t("Main.title")}</Text>
      </div>
      <div className={styles.right}>
        <Dropdown menu={{ items: menus, onClick: handleMenuClick }}>
          <Space
            className={styles.userInfo}
            direction="horizontal"
            align="center"
          >
            <img className={styles.avatar} src={userImg} />
            <Text className={styles.userName}>{userInfo.nickname}</Text>
            <DownOutlined />
          </Space>
        </Dropdown>
      </div>
    </Header>
  );
};

export default MainHeader;
