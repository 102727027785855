import { request } from "../utils/request";
import { AxiosResponse } from "axios";
import {
  ICommonFilterTablePaginationApi,
  ICommonFilterTablePaginationReq,
  ICommonFilterTableSearchApi,
} from "./types";

export const getRoleMenuApi: ICommonFilterTableSearchApi = () => {
  return request.get("/roles/pagination/menu");
};

export const getRolesByPaginationApi: ICommonFilterTablePaginationApi = (
  params: ICommonFilterTablePaginationReq
) => {
  return request.post("/roles/pagination", params);
};
