import { nanoid } from "nanoid";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { findItemFromPathName } from "../router/utils";
import { addMenu, removeCurrent, removeMenu } from "../store/menuReducer";

export const useMenuTag = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const location = useLocation()
  const goRouteAndAddTag = (path: string, title: string) => {
    nav(path);
    dispatch(
      addMenu({
        title,
        path,
        id: nanoid(5),
      })
    );
  };
  const closeCurrentRouteAndTag = () => {
    dispatch(removeCurrent())
  }
  return {
    goRouteAndAddTag,
    closeCurrentRouteAndTag
  };
};
