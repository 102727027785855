import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Allocation from '../../pages/Allocation/Allocation';
import { getCommonAssignApi, ICommonFormInfoRes } from "../../api/common";
import { useRequest } from "ahooks";

const AssignInfoPage = forwardRef((props: {
    info_api: string
    pagination_ids?: Array<string>
    inited?: (formInfo: ICommonFormInfoRes) => void
}, ref) => {
    useImperativeHandle(ref, () => ({
        ...assignInfoRef.current
    }))

    const [targetId, setTargetId] = useState('')
    const [api, setApi] = useState('')

    // const { data, run } = useRequest(async (api, target_id: string) => {
    //     return await getCommonAssignApi(api, target_id)
    // }, {
    //     manual: true,
    //     onSuccess(data) {
    //         setTargetId(data.data.target_id)

    //     }
    // })

    useEffect(() => {
        if (props.pagination_ids?.length) {
            setTargetId(props.pagination_ids[0])
            // setApi(props.info_api.replace('{pagination_id}', props.pagination_ids[0]))
            const infoApi = props.info_api.replace('{pagination_id}', '')
            setApi(infoApi)
            // run(infoApi, props.pagination_ids[0])
        }
    }, [props.info_api, props.pagination_ids])



    const assignInfoRef = useRef<any>()

    return <div className="common-assign-page">
        {
            targetId && api && <Allocation ref={assignInfoRef} target_id={targetId} api={api}></Allocation>
        }
    </div>
})

export default AssignInfoPage;