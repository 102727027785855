import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import {
  Row,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Space,
  Button,
  message,
  Table,
  TableColumnType,
} from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import {
  batchCreateCourseApi,
  batchCreateCourseInfoApi,
  IBatchCreateCourseParam,
  IClassSchedule,
} from "../../api/classes";
import { ICommonOption } from "../../api/common";
import CommonSelect from "../../components/common-select/CommonSelect";
import { useMenuTag } from "../../hooks/useMenuTag";
import { request } from "../../utils/request";
import styles from "./NewClasses.module.scss";
const NewClasses: FC = () => {
  const [form] = Form.useForm();
  const { closeCurrentRouteAndTag } = useMenuTag();
  const [schoolOptions, setSchoolOptions] = useState<Array<ICommonOption>>([]);
  const [formData, setFormData] = useState<{
    school_id: string | undefined;
    classroom_id: string | undefined;
    course_master_id: string | undefined;
    course_id: string | undefined;
    lesson_begin_num: number | undefined;
    lesson_end_num: number | undefined;
    class_num: number | undefined;
    date_start: string | undefined;
    time_start: string | undefined;
    lesson_per_week: number | undefined;
    weekdays: Array<string> | undefined;
    teacher_id: string | undefined;
  }>({
    school_id: undefined,
    classroom_id: undefined,
    course_master_id: undefined,
    course_id: undefined,
    lesson_begin_num: undefined,
    lesson_end_num: undefined,
    class_num: undefined,
    date_start: undefined,
    time_start: undefined,
    lesson_per_week: undefined,
    weekdays: undefined,
    teacher_id: undefined,
  });
  const [courseInfo, setCourseInfo] = useState<{
    lesson_begin_num: number;
    lesson_end_num: number;
    show_lesson_num: boolean;
    show_class_num: boolean;
    lesson_per_week: Array<number>;
    teachers: Array<{
      teacher_id: string;
      teacher_name: string;
    }>;
  }>({
    lesson_begin_num: 0,
    lesson_end_num: 0,
    show_lesson_num: false,
    show_class_num: false,
    lesson_per_week: [],
    teachers: [],
  });

  const [classSchedules, setClassSchedules] = useState<Array<IClassSchedule>>(
    []
  );

  const weekOptions = [
    {
      label: "Monday",
      value: "mon",
    },
    {
      label: "Tuesday",
      value: "tue",
    },
    {
      label: "Wednesday",
      value: "wed",
    },
    {
      label: "Thursday",
      value: "thu",
    },
    {
      label: "Friday",
      value: "fri",
    },
    {
      label: "Saturday",
      value: "sat",
    },
    {
      label: "Sunday",
      value: "sun",
    },
  ];

  const columns: Array<TableColumnType<IClassSchedule>> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Lesson",
      dataIndex: "lesson",
      key: "lesson",
    },
    {
      title: "Begin Time",
      dataIndex: "begin_time",
      key: "begin_time",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
    },
    {
      title: "Conflect",
      dataIndex: "is_conflicted_str",
      key: "is_conflicted_str",
    },
    {
      title: "Details",
      dataIndex: "conflict_details",
      key: "conflict_details",
    },
    {
      title: "",
      key: "_op",
      dataIndex: "_op",
      render(value, record, index) {
        return (
          <Space>
            <Button
              type="link"
              loading={batchCreateCourseLoading}
              icon={<DeleteOutlined />}
              onClick={() => {
                batchCreateCourseRun({
                  delete: [record.id],
                });
              }}
            >
              Delete
            </Button>
            <Button
              type="link"
              loading={batchCreateCourseLoading}
              icon={<EditOutlined />}
              onClick={() => {
                batchCreateCourseRun({
                  skip: [record.id],
                });
              }}
            >
              Skip
            </Button>
          </Space>
        );
      },
    },
  ];

  const onFormValuesChange = (values: any) => {
    setFormData({
      ...formData,
      ...values,
    });
  };

  const handleStageValueChange = (newStage: string | undefined) => {
    if (newStage) {
      batchCreateCourseInfoApi(newStage).then((res) => {
        setCourseInfo({
          lesson_begin_num: res.data.lesson_begin_num,
          lesson_end_num: res.data.lesson_end_num,
          show_lesson_num: res.data.show_lesson_num,
          show_class_num: res.data.show_class_num,
          lesson_per_week: res.data.lesson_per_week || [],
          teachers: res.data.teachers || [],
        });
        if (!res.data.show_lesson_num) {
          // 清除已经设置过的值
          form.setFieldsValue({
            ...formData,
            lesson_begin_num: undefined,
            lesson_end_num: undefined,
            class_num: res.data.show_class_num ? res.data.class_num == null ? undefined : res.data.class_num : undefined,
          });
        } else {
          form.setFieldsValue({
            ...formData,
            lesson_begin_num: res.data.lesson_begin_num || 1,
            lesson_end_num: res.data.lesson_end_num || 1,
            class_num:
            res.data.class_num == null ? undefined : res.data.class_num,
          });
        }
        setFormData((state) => {
          return {
            ...state,
            lesson_begin_num: res.data.show_lesson_num ? (res.data.lesson_begin_num || 1) : undefined,
            lesson_end_num: res.data.show_lesson_num ? (res.data.lesson_end_num || 1) : undefined,
            class_num:
            res.data.show_class_num ? res.data.class_num == null ? undefined : res.data.class_num : undefined
          };
        });
      });
    }
  };

  const { loading: batchCreateCourseLoading, run: batchCreateCourseRun } =
    useRequest(
      async (params?: Partial<IBatchCreateCourseParam>) => {
        try {
          debugger
          let res = await batchCreateCourseApi({
            mode: "preview",
            classroom_id: formData.classroom_id as string,
            course_id: formData.course_id as string,
            lesson_begin_num: formData.lesson_begin_num as number,
            lesson_end_num: formData.lesson_end_num as number,
            class_num: formData.class_num as number,
            teacher_id: formData.teacher_id as string,
            date_start: formData.date_start
              ? dayjs(formData.date_start).format("YYYY-MM-DD")
              : "",
            time_start: formData.time_start
              ? dayjs(formData.time_start).format("HH:mm")
              : "",
            lesson_per_week:
              formData.lesson_per_week == null
                ? 0
                : Number(formData.lesson_per_week),
            weekdays: formData.weekdays as Array<string>,
            skip: [],
            delete: [],
            ...params,
          });
          setClassSchedules(res.data.class_schedules);
          message.success('Success')
          debugger
          if (params?.mode === 'confirm') {
            // 关闭页面
            closeCurrentRouteAndTag()
            debugger
          }
        } catch (e) {
          // setClassSchedules([]);
          message.error("error");
        }
      },
      {
        manual: true
      }
    );

  const handlePreview = async () => {
    batchCreateCourseRun();
  };

  const handleTableRowClassName = (record: IClassSchedule) => {
    if (record.is_conflicted) {
      return "is-conflicted";
    } else {
      return "";
    }
  };
  const handleConfirm = () => {
    batchCreateCourseRun({
      mode: 'confirm'
    })
  }
  const handleCancel = () => {
    closeCurrentRouteAndTag()
  }

  useEffect(() => {}, []);

  return (
    <div className={styles.addClassPage}>
      <Form
        className={styles.filter}
        form={form}
        initialValues={formData}
        onValuesChange={onFormValuesChange}
      >
        <div className={styles.timeline}>
          <div className={styles.timelineItem}>
            <div className="num">1</div>
            <div className="title">Venue</div>
            <div className="line"></div>
          </div>
          <div className={styles.timelineItem}>
            <div className="num">2</div>
            <div className="title">Course</div>
            <div className="line"></div>
          </div>
          <div className={styles.timelineItem}>
            <div className="num">3</div>
            <div className="title">Time</div>
            <div className="line"></div>
          </div>
          <div className={styles.timelineItem}>
            <div className="num">4</div>
            <div className="title">Teacher</div>
          </div>
        </div>
        <div className="right">
          <Row>
            <Form.Item label="School" name={"school_id"}>
              <CommonSelect
                placeholder="Please Select"
                api="/schools/list"
                valueKey="school_id"
                labelKey="school_name"
              />
            </Form.Item>
            <Form.Item label="Classroom" name={"classroom_id"}>
              <CommonSelect
                placeholder="Please Select"
                api="/classrooms/list?school_id={school_id}"
                deps={{
                  school_id: formData.school_id,
                }}
                valueKey="classroom_id"
                labelKey="classroom_name"
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="Course" name={"course_master_id"}>
              <CommonSelect
                width="250px"
                placeholder="Please Select"
                api="/courses/master/list"
                valueKey="course_master_id"
                labelKey="course_master_name"
              />
            </Form.Item>
            <Form.Item label="Stage" name={"course_id"}>
              <CommonSelect
                width="250px"
                placeholder="Please Select"
                api="/courses/list?course_master_id={course_master_id}"
                deps={{
                  course_master_id: formData.course_master_id,
                }}
                valueKey="course_id"
                labelKey="course_name"
                onValueChange={handleStageValueChange}
              />
            </Form.Item>
            {courseInfo.show_lesson_num && (
              <Form.Item label="From Lesson" name={"lesson_begin_num"}>
                <InputNumber min={courseInfo.lesson_begin_num || 1} />
              </Form.Item>
            )}
            {courseInfo.show_lesson_num && (
              <Form.Item label="To Lesson" name={"lesson_end_num"}>
                <InputNumber min={1} max={courseInfo.lesson_end_num} />
              </Form.Item>
            )}
            {courseInfo.show_class_num && (
              <Form.Item label="No. of classes" name={"class_num"}>
                <InputNumber min={1} />
              </Form.Item>
            )}
          </Row>
          <Row>
            <Form.Item label="Strat from" name={"date_start"}>
              <DatePicker />
            </Form.Item>
            <Form.Item label="Time" name={"time_start"}>
              <DatePicker picker="time" format={"HH:mm"} />
            </Form.Item>
            <Form.Item label="Lesson per week" name={"lesson_per_week"}>
              <CommonSelect
                placeholder="Please Select"
                options={courseInfo.lesson_per_week.map((w) => ({
                  label: w + "",
                  value: w,
                }))}
              />
            </Form.Item>
            <Form.Item label="Weekdays" name={"weekdays"}>
              <CommonSelect
                multiple={true}
                placeholder="Please Select"
                options={weekOptions}
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="Select Teacher" name={"teacher_id"}>
              <CommonSelect
                placeholder="Please Select"
                options={courseInfo.teachers}
                valueKey="teacher_id"
                labelKey="teacher_name"
              />
            </Form.Item>
          </Row>
        </div>
      </Form>
      <Row>
        <Space>
          <Button
            type="primary"
            loading={batchCreateCourseLoading}
            onClick={handlePreview}
          >
            Preview Schedule
          </Button>
        </Space>
      </Row>
      <Table
        className={styles.table}
        scroll={{ y: 300 }}
        dataSource={classSchedules}
        columns={columns}
        rowClassName={handleTableRowClassName}
      />
      <Row>
        <Space>
          <Button type="primary" loading={batchCreateCourseLoading} onClick={handleConfirm}>Confirm and Create Class</Button>
          <Button type="default" loading={batchCreateCourseLoading} onClick={handleCancel}>Cancel</Button>
        </Space>
      </Row>
    </div>
  );
};

export default NewClasses;
