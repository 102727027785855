import { message } from "antd";
import classNames from "classnames";
import { FC, Key, Ref, useRef } from "react";
import { getAuthoritiesByPaginationApi, getAuthorityMenuApi } from "../../api/authority";
import { getCommonFilterTableByPaginationApi, getCommonFilterTableMenuApi } from "../../api/common";
import { ICommonFilterTablePaginationReq } from "../../api/types";
import CommonFilterTablePagination, {
  ITableSelectedData,
} from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import { useDialog } from "../../hooks/useDialog";
import Allocation from "../Allocation/Allocation";
import styles from "./WaitingList.module.scss";

const WaitingList: FC = () => {
  const { dialogs, openDialog, closeDialog } = useDialog();

  const allocationRef = useRef<any>()

  const operationFuncs = {
    
  };

  return (
    <>
      <div className={classNames({
        [styles.page]:true,
        ['common-filter-table-pagination-page']: true
      })}>
        <CommonFilterTablePagination
          getSearchPromise={() => getCommonFilterTableMenuApi('/students/waiting/pagination/menu')}
          getListPromise={(params: ICommonFilterTablePaginationReq ) => getCommonFilterTableByPaginationApi(params, '/students/waiting/pagination')}
          rowKey="waiting_id"
          operationFuncs={operationFuncs}
          // operation={<FilterOperation />}
        ></CommonFilterTablePagination>
      </div>
    </>
  );
};

export default WaitingList;
