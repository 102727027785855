import { Button, Col, Form, Row, Space } from "antd";
import { FC, ReactElement, useEffect, useState } from "react";
import { ICommonFilterTableSearch } from "../../api/types";
import FilterItem from "./FilterItem";
import styles from "./FIlter.module.scss";

export interface IFilterProps {
  filters: Array<ICommonFilterTableSearch>;
  onSearch: (filterBy: Record<string, any>) => void;
  operation?: any;
  onResetSorting: () => void;
}

const Filter: FC<IFilterProps> = (props: IFilterProps) => {
  const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } };

  const [form] = Form.useForm();

  const [filterBy, setFilterBy] = useState<Record<string, any>>({});

  const onValuesChange = (values: any) => {
    const fixedValues: Record<string, any> = {};
    for (let key in values) {
      fixedValues[key] = values[key] === null ? undefined : values[key];
    }
    setFilterBy((state) => {
      return {
        ...state,
        ...fixedValues,
      };
    });
  };

  const handleSearch = (values: any) => {
    props.onSearch && props.onSearch(filterBy);
    // 触发搜索
  };

  const handlelResetFiters = () => {
    const formValue = form.getFieldsValue();
    for (let key in formValue) {
      formValue[key] = undefined;
    }

    const defaultValues = props.filters.reduce((prev, current) => {
      if (current.default) {
        prev[current.key || current.propKey] = current.default;
      }
      return prev;
    }, {} as Record<string, any>);
    form.setFieldsValue({
      ...formValue,
      ...defaultValues,
    });
    setFilterBy(defaultValues);
    props.onSearch && props.onSearch(defaultValues);
  };

  const handlelResetSortBy = () => {
    props.onResetSorting && props.onResetSorting();
    // const formValue = form.getFieldsValue();
    // for (let key in formValue) {
    //   formValue[key] = undefined;
    // }
    // form.setFieldsValue(formValue);
    // setFilterBy({});
    // props.onSearch && props.onSearch({});
  };

  useEffect(() => {
    setFilterBy(
      props.filters.reduce((prev, current) => {
        if (current.default) {
          prev[current.key || current.propKey] = current.default;
        }
        return prev;
      }, {} as Record<string, any>)
    );
  }, [props.filters]);

  return (
    <Form
      className={styles.filter}
      layout="inline"
      form={form}
      // labelCol={{ span: 8 }}
      // wrapperCol={{ span: 16 }}
      labelWrap={true}
      onValuesChange={onValuesChange}
      onFinish={handleSearch}
    >
      {/* {JSON.stringify(filterBy)} */}
      {/* <Row> */}
      <Space wrap>
        {props.filters.map((searcher, index) => {
          return (
            <FilterItem
              {...searcher}
              key={searcher.key}
              propKey={searcher.key}
              filterBy={filterBy}
            ></FilterItem>
          );
        })}
        {/* <Space
          style={{
            justifyContent: "end",
            display: "flex",
            marginBottom: "12px",
            marginLeft: "10px",
          }}
        > */}
        <Button type="primary" htmlType="submit">
          Search
        </Button>
        <Button type="default" onClick={handlelResetFiters}>
          Reset Filters
        </Button>
        <Button type="default" onClick={handlelResetSortBy}>
          Reset Sorting
        </Button>

        {props.operation}
        {/* </Space> */}
        {/* </Row> */}
      </Space>
    </Form>
  );
};

export default Filter;
