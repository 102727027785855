import { MenuProps } from "antd";
import CourseImg from "../assets/layout/menu/Course.svg";
import AccountsImg from "../assets/layout/menu/Accounts.svg";
import { lazy, ReactNode } from "react";
import Accounts from "../pages/Accounts/Accounts";
import Courses from "../pages/Courses/Courses";
import Sales from "../pages/Sales/Sales";
import { loopMenus } from "./utils";
import Classes from "../pages/Classes/Classes";
import NewClasses from "../pages/Classes/NewClasses";
import KeepAlive from "react-activation";
import { getUser, IUser } from "../utils/user";
import { IPermission } from "../api/auth";
import Allocation from "../pages/Allocation/Allocation";
import Teachers from "../pages/Teachers/Teachers";
import Users from "../pages/Users/Users";
import Roles from "../pages/Roles/Roles";
import Authorities from "../pages/Authorities/Authorities";
import Attendances from "../pages/Attendances/Attendances";
import StudentLeaves from "../pages/StudentLeaves/StudentLeaves";
import StaffLeaves from "../pages/StaffLeaves/StaffLeaves";
import Students from "../pages/Students/Students";
import Staffs from "../pages/Staffs/Staffs";
import WaitingList from "../pages/WaitingList/WaitingList";
import Reserved from "../pages/Reserved/Reserved";
import Appointments from "../pages/Appointments/Appointments";
import Signup from "../pages/Signup/Signup";
import CourseSale from "../pages/Accounting/CourseSale/CourseSale";
import Transaction from "../pages/Accounting/Transaction/Transaction";
import Expense from "../pages/Accounting/Expense/Expense";
import Income from "../pages/Accounting/Income/Income";
import SchoolCalendarInfo from "../pages/schools/SchoolCalendarInfo/SchoolCalendarInfo";
import AssignClass from "../pages/AssignClass/AssignClass";
import CalendarPage from "../pages/CalendarPage/CalendarPage";
import PaginationPage from "../pages/PaginationPage/PaginationPage";

export type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export interface IRouterMenu {
  title: string;
  key: string;
  path: string;
  icon?: JSX.Element;
  element?: JSX.Element;
  children?: Array<IRouterMenu>;
  parent?: IRouterMenu;
  hideMenu?: boolean;
}

const KeepAliveWrapper = (props: {
  name: string;
  path: string;
  children: ReactNode;
}) => {
  return (
    <KeepAlive
      name={props.name}
      when={true}
      // name={props.name}
      // when={() => {
      //   try {
      //     debugger
      //     const menus: Array<any> = JSON.parse(
      //       JSON.parse(localStorage.getItem("menus") || "[]")
      //     );
      //     return menus.findIndex((menu) => menu.path === props.path) > -1;
      //   } catch {
      //     return true;
      //   }
      // }}
    >
      {/* <Classes /> */}
      {props.children}
    </KeepAlive>
  );
};

export const menuMap: Record<string, IRouterMenu> = {
  PaginationPage: {
    title: "PaginationPage",
    key: "PaginationPage",
    path: "/PaginationPage/:name",
    element: (
      // <KeepAliveWrapper name="PaginationPage" path="/PaginationPage">
      //   <PaginationPage />
      // </KeepAliveWrapper>
      <PaginationPage />
    ),
  },
  CalendarPage: {
    title: "CalendarPage",
    key: "CalendarPage",
    path: "/CalendarPage/:name",
    element: (
      <KeepAliveWrapper name="CalendarPage" path="CalendarPage">
        <CalendarPage />
      </KeepAliveWrapper>
    ),
  },
  AssignClass: {
    title: "Assign Class",
    key: "AssignClass",
    path: "/assign-class/:waitingId",
    element: (
      <KeepAliveWrapper name="AssignClass" path="/assign-class">
        <AssignClass />
      </KeepAliveWrapper>
    ),
  },
  Calendar: {
    title: "Calendars",
    key: "Calendar",
    path: "/calendar",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Calendar" path="/calendar">
        <SchoolCalendarInfo />
      </KeepAliveWrapper>
    ),
  },
  Income: {
    title: "Other Income",
    key: "Income",
    path: "/income",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Income" path="/income">
        <Income />
      </KeepAliveWrapper>
    ),
  },
  Expense: {
    title: "Expense",
    key: "Expense",
    path: "/expense",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Expense" path="/expense">
        <Expense />
      </KeepAliveWrapper>
    ),
  },
  Transaction: {
    title: "Transaction",
    key: "Transaction",
    path: "/transaction",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Transaction" path="/transaction">
        <Transaction />
      </KeepAliveWrapper>
    ),
  },
  CourseSale: {
    title: "Course Sale",
    key: "CourseSale",
    path: "/coursesale",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="CourseSale" path="/coursesale">
        <CourseSale />
      </KeepAliveWrapper>
    ),
  },
  SignUp: {
    title: "SignUp",
    key: "SignUp",
    path: "/signup",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="SignUp" path="/signup">
        <Signup />
      </KeepAliveWrapper>
    ),
  },
  Appointment: {
    title: "Appointments",
    key: "Appointment",
    path: "/appointment",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Appointment" path="/appointment">
        <Appointments />
      </KeepAliveWrapper>
    ),
  },
  Reserved: {
    title: "Reserved",
    key: "Reserved",
    path: "/reserved",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Reserved" path="/reserved">
        <Reserved />
      </KeepAliveWrapper>
    ),
  },
  WaitingList: {
    title: "Waiting List",
    key: "WaitingList",
    path: "/waitingList",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="WaitingList" path="/waitingList">
        <WaitingList />
      </KeepAliveWrapper>
    ),
  },
  Staff: {
    title: "Staffs",
    key: "Staff",
    path: "/staffs",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Staff" path="/staffs">
        <Staffs />
      </KeepAliveWrapper>
    ),
  },
  Student: {
    title: "Students",
    key: "Student",
    path: "/students",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Student" path="/students">
        <Students />
      </KeepAliveWrapper>
    ),
  },
  StaffLeave: {
    title: "Staff Leaves",
    key: "StaffLeave",
    path: "/staffLeave",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="staffLeave" path="/staffLeaves">
        <StaffLeaves />
      </KeepAliveWrapper>
    ),
  },
  StudentLeave: {
    title: "Student Leaves",
    key: "StudentLeave",
    path: "/studentLeaves",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="StudentLeave" path="/studentLeaves">
        <StudentLeaves />
      </KeepAliveWrapper>
    ),
  },
  Attendance: {
    title: "Attendances",
    key: "Attendance",
    path: "/attendances",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Attendance" path="/attendances">
        <Attendances />
      </KeepAliveWrapper>
    ),
  },
  Users: {
    title: "All Users",
    key: "Users",
    path: "/users",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="users" path="/users">
        <Users />
      </KeepAliveWrapper>
    ),
  },
  Roles: {
    title: "All Roles",
    key: "Roles",
    path: "/roles",
    icon: <img src={CourseImg} />,

    element: (
      <KeepAliveWrapper name="roles" path="/roles">
        <Roles />
      </KeepAliveWrapper>
    ),
  },
  Authorities: {
    title: "All Authorities",
    key: "Authorities",
    path: "/authorities",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="authorities" path="/authorities">
        <Authorities />
      </KeepAliveWrapper>
    ),
  },
  Course: {
    title: "Courses",
    key: "Course",
    path: "/courses",
    icon: <img src={CourseImg} />,
    element: (
      <KeepAliveWrapper name="Course" path="/courses">
        <Courses />
      </KeepAliveWrapper>
    ),
  },
  Classes: {
    title: "All Classes",
    key: "Classes",
    path: "/classes",
    icon: <img src={AccountsImg} />,
    element: (
      <KeepAliveWrapper name="classes" path="/classes">
        <Classes />
      </KeepAliveWrapper>
    ),
  },
  NewClasses: {
    title: "New Classes",
    key: "NewClasses",
    path: "/classes/new",
    element: <NewClasses />,
    hideMenu: true,
  },
  Teacher: {
    title: "All Teachers",
    key: "Teacher",
    path: "/teachers",
    icon: <img src={AccountsImg} />,
    element: (
      <KeepAliveWrapper name="teachers" path="/teachers">
        <Teachers />
      </KeepAliveWrapper>
    ),
  },
  Allocation: {
    title: "Allocation",
    key: "Allocation",
    path: "/allocation",
    element: (
      <Allocation
        api="/teachers/courses/assign/info"
        target_id="dfc3aa3b-df18-11eb-ac36-02f43361ec14"
      />
    ),
    hideMenu: true,
  },
};

// export const menus: IRouterMenu[] = [
//   {
//     title: "Course",
//     key: "Courses",
//     path: "/course",
//     icon: <img src={CourseImg} />,
//     element: <Course />,
//   },
//   {
//     title: "Accounts",
//     key: "accounts",
//     path: "/accounts",
//     icon: <img src={AccountsImg} />,
//     element: <Accounts />,
//   },
//   {
//     title: "Classes",
//     key: "classes",
//     path: "/classes",
//     icon: <img src={AccountsImg} />,
//     // element: <Classes />,
//     element: (
//       <KeepAliveWrapper name="classes" path="/classes"></KeepAliveWrapper>
//     ),
//   },
//   {
//     title: "New Classes",
//     key: "addclasses",
//     path: "/classes/new",
//     element: <NewClasses />,
//     hideMenu: true,
//   },
//   {
//     title: "Sales",
//     key: "sales",
//     path: "/sales",
//     icon: <img src={AccountsImg} />,
//     // element: <Sales />,
//     children: [
//       {
//         title: "Sales",
//         key: "sale",
//         path: "",
//         element: <Sales />,
//       },
//     ],
//   },
// ];

export let menus: IRouterMenu[] = [];

export const getLatestMenus = () => {
  const user: IUser | null = getUser();
  const permissions = user?.permissions || [];
  menus = composeMenu(permissions);
  return menus;
};

const composeMenu = (permissions: IPermission[]) => {
  return permissions.map((item) => {
    let permission = item;
    const innerRouter = menuMap[permission.name];
    if (permission.children?.length) {
      permission.children = composeMenu(permission.children) as any;
    }
    const res = {
      ...permission,
      ...(innerRouter || ({} as any)),
      title: permission.title,
    };
    if (!res.key) {
      res.key = res.name;
    }
    return res as IRouterMenu;
  });
};

loopMenus<IRouterMenu>((item: any, parent: any) => {
  item.fullPath = parent ? `${parent.fullPath}/${item.path}` : item.path;
  if (item.fullPath.endsWith("/")) {
    item.fullPath = item.fullPath.slice(0, -1);
  }
  item.parent = parent;
}, menus);
