import { request } from "../utils/request";
import { AxiosResponse } from "axios";
import {
  IAssignInfoRes,
  ICommonFilterTablePaginationApi,
  ICommonFilterTablePaginationReq,
  ICommonFilterTableSearchApi,
} from "./types";

export const getAuthorityMenuApi: ICommonFilterTableSearchApi = () => {
  return request.get("/authorities/pagination/menu");
};

export const getAuthoritiesByPaginationApi: ICommonFilterTablePaginationApi = (
  params: ICommonFilterTablePaginationReq
) => {
  return request.post("/authorities/pagination", params);
};
