import { Spin } from 'antd'
import { useApp } from '../../context/app-context'
import './Loading.scss'

const LoadingComponent = () => {
    const app = useApp();
    const loading = app.loading
    if (!loading) return null
    return <div className="global-loading-wrapper">
        <Spin spinning={true}></Spin>
    </div>
}
export default LoadingComponent