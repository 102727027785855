import ApiList from "../common-filter-table-pagination/ApiList";
import DateFilter from "../common-filter-table-pagination/Date";
import { IFilterItemProps } from "../common-filter-table-pagination/FilterItem";
import Int from "../common-filter-table-pagination/Int";
import Keyword from "../common-filter-table-pagination/Keyword";
import TimeFilter from "../common-filter-table-pagination/TIme";
import Null from "./Null";
import { ICommonFormItem, ICommonFormItemType } from "./types";

const TypeMap: Record<string, any> = {
  [ICommonFormItemType.Input]: Keyword,
  [ICommonFormItemType.Number]: Int,
  [ICommonFormItemType.Date]: DateFilter,
  [ICommonFormItemType.Enum]: ApiList,
  [ICommonFormItemType.Null]: Null,
  [ICommonFormItemType.Time]: TimeFilter,
  [ICommonFormItemType.ApiList]: ApiList
};

const CommonFormItem = (
  props: IFilterItemProps & {
    required?: boolean;
    list_api?: string
    deps?: Array<string>
    transformOption?: {
      value: string
      label: string
    }
  }
) => {
  const Component = TypeMap[props.type] || Null;
  return (
    <Component
      {...props}
      required={props.required}
      list={props.list}
      list_api={props.list_api}
      deps={props.deps}
      transformOption={props.transformOption}
    ></Component>
  );
};

export default CommonFormItem;
