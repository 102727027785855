import {
  Badge,
  Button,
  Calendar,
  CalendarProps,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
} from "antd";
import classNames from "classnames";
import zh_CN from 'antd/locale/zh_CN'
import dayjs, { Dayjs } from "dayjs";
import { nanoid } from "nanoid";
import { MouseEventHandler, SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  getCommonCalendarDataApi,
  getCommonCalendarInfoApi,
  ICommonCalendarDataParam,
  ICommonCalendarDataRes,
  ICommonCalendarEventRes,
  IComonCalendarInfoRes,
} from "../../api/common";
import { useDialogRef } from "../../hooks/useDialog";
import { executeCrudEvent } from "../common-filter-table-pagination/operator";
// import styles from "../common-filter-table-pagination/FIlter.module.scss";
import styles from "./CommonCalendarInfo.module.scss";
import zhCN from "antd/es/locale/zh_CN";

const locales: Record<string, any> = {
  cn: zhCN
};

export interface ICommonCalendarInfoProps {
  infoApi: string;
  dataApi: string;
}

const CommonCalendarInfo = (props: ICommonCalendarInfoProps) => {
  const [form] = Form.useForm();

  const [filterBy, setFilterBy] = useState<Record<string, any>>({
    year_month: dayjs().format("YYYY-MM"),
  });

  const [calendarInfo, setCalendarInfo] = useState<IComonCalendarInfoRes>({
    prefix: "",
    targets: [],
    default_target_name: "",
    default_target_id: "",
    show_searcher: false,
    searcher_prefix: "",
    searcher_display: "",
    default_calendar_type: "",
  });

  const [calendarDatas, setCalendarDatas] = useState<
    ICommonCalendarDataRes["calendars"]
  >([]);

  const onValuesChange = (values: any) => {
    const fixedValues: Record<string, any> = {};
    for (let key in values) {
      fixedValues[key] = values[key] === null ? undefined : values[key];
    }
    setFilterBy((state) => {
      return {
        ...state,
        ...fixedValues,
      };
    });
  };

  const handleSearch = (values: any) => {
    // props.onSearch && props.onSearch(filterBy);
    // 触发搜索
    let originYearMonth = values.year_month;
    if (values.year_month && values.year_month.format) {
      values.year_month = values.year_month.format("YYYY-MM");
    }
    setFilterBy(values);
    setCalendarDate(originYearMonth);
    // getCalendarInfo()
    Promise.resolve().then(() => {
      getCalendarDatas({
        calendar_type: calendarInfo.default_calendar_type,
        year_month: values.year_month,
        target_id: values.target_id,
        searcher: values.searcher,
      });
    });
  };
  const handlelResetFiters = () => {
    const formValue = form.getFieldsValue();
    for (let key in formValue) {
      formValue[key] = undefined;
    }
    // if (calendarInfo.default_target_id) {
    // } else {
    // }
    form.setFieldValue("searcher", "");
    setFilterBy((filter) => {
      return {
        ...filter,
        searcher: "",
      };
    });
  };

  const handleTargetIdChange = (newTargetId: string) => {
    setFilterBy((filter) => {
      return {
        ...filter,
        target_id: newTargetId,
      };
    });
    handleSearch({
      ...filterBy,
      target_id: newTargetId,
      year_month: filterBy.year_month ? dayjs(filterBy.year_month) : dayjs(),
    });
  };

  const getCalendarDatas = (params: ICommonCalendarDataParam) => {
    getCommonCalendarDataApi(props.dataApi, params).then((res) => {
      setCalendarDatas(res.data.calendars);
    });
  };

  const getCalendarInfo = (callback?: (d: IComonCalendarInfoRes) => void) => {
    getCommonCalendarInfoApi(props.infoApi).then((res) => {
      setCalendarInfo(res.data);
      if (res.data.default_target_id) {
        setFilterBy((filter) => {
          return {
            ...filter,
            target_id: res.data.default_target_id,
          };
        });
        form.setFieldValue("target_id", res.data.default_target_id);
      }
      setTimeout(() => {
        callback && callback(res.data);
      }, 100);
    });
  };

  useEffect(() => {
    form.setFieldValue("year_month", dayjs());
    getCalendarInfo((calendarInfo) => {
      if (calendarInfo.default_target_id) {
        getCalendarDatas({
          calendar_type: calendarInfo.default_calendar_type,
          year_month: dayjs().format('YYYY-MM'),
          target_id: calendarInfo.default_target_id,
          searcher: '',
        });
      }
    });
  }, []);


  const crudDialogRef = useDialogRef();
  const crudInfoFormRef = useRef<any>();
  
  const handleEventClick = (event: ICommonCalendarEventRes, e: SyntheticEvent) => {
    if (event.component) {
      if (event.component?.type === 'crud') {
       executeCrudEvent(event, {
        crudDialogRef,
        crudInfoFormRef,
        selectedRowKeys: event.component.body.pagination_ids
       })

      }
      e.preventDefault()
    }
    
  }

  const dateCellRender = (value: Dayjs) => {
    const listData = calendarDatas.find((data) => {
      return data.date === value.format("YYYY-MM-DD");
    });
    if (!listData)
      return (
        <div className="events">
          <span className="myDate">{value.format("DD")}</span>
        </div>
      );
    return (
      <div className="events">
        <span
          className={classNames({
            myDate: true,
            red: listData.is_holiday,
          })}
        >
          {value.format("DD")}
        </span>
        {listData.events.map((event) => (
          <Tooltip title={event.title}>
            <div key={event.title} onClick={(e: any) => handleEventClick(event, e)}>
              <Badge status="default" />
              <span style={{
                color: event.color,
                textDecoration: 'underline'
              }}>{event.title}</span>
            </div>
          </Tooltip>
        ))}
      </div>
    );
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    return dateCellRender(current);
    // return info.originNode;
  };

  //   const fullCellRender: CalendarProps<Dayjs>["fullCellRender"] = (date, info) => {
  //     return <span>12</span>
  //   }

  const handleCalendarChange = (newDate: Dayjs) => {
    let oldDate = calendarDate;
    setCalendarDate(newDate);
    if (oldDate.format("YYYY-MM") !== newDate.format("YYYY-MM")) {
      getCalendarDatas({
        calendar_type: calendarInfo.default_calendar_type,
        year_month: filterBy.year_month,
        target_id: filterBy.target_id,
        searcher: filterBy.searcher,
      });
    }
  };

  const handleHeaderRender: any = ({ value, type, onChange, onTypeChange }: any) => {
    return undefined;
  };

  const [calendarDate, setCalendarDate] = useState(dayjs());

  const handleSelectDateChange = (newDate: Dayjs | null) => {
    form.setFieldValue("year_month", newDate || dayjs());
    handleSearch({
      ...filterBy,
      year_month: newDate ? newDate : dayjs(),
    });
    // handleCalendarChange(newDate || dayjs())
  }

  return (
    <div className={styles.container}>
      <Form
        className={styles.filter}
        layout="inline"
        form={form}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        labelWrap={true}
        onValuesChange={onValuesChange}
        onFinish={handleSearch}
      >
        <Space wrap>
          <Form.Item label={"Select Date"} name="year_month">
            <DatePicker
              placeholder="Please Select"
              format={"YYYY-MM"}
              picker="month"
              allowClear={false}
              onChange={handleSelectDateChange}
            />
          </Form.Item>
          <Form.Item label={calendarInfo.prefix} name="target_id">
            <Select
              style={{ width: "220px" }}
              placeholder={"Please Select"}
              options={calendarInfo.targets.map((target) => {
                return {
                  label: target.target_name,
                  value: target.target_id,
                };
              })}
              onChange={handleTargetIdChange}
            ></Select>
          </Form.Item>
          {calendarInfo.show_searcher && (
            <Form.Item label={calendarInfo.searcher_prefix} name="searcher">
              <Input placeholder={calendarInfo.searcher_display} />
            </Form.Item>
          )}

          <Button type="primary" htmlType="submit">
            Query
          </Button>
          <Button type="default" onClick={handlelResetFiters}>
            Clear
          </Button>
        </Space>
      </Form>
      <div className={styles.calendar}>
      <ConfigProvider locale={locales['cn']}>
        <Calendar
          
          cellRender={cellRender}
          value={calendarDate}
          onChange={handleCalendarChange}
        />
      </ConfigProvider>
      </div>
    </div>
  );
};

export default CommonCalendarInfo;
