import { message } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { ICommonFormInfoRes } from "../../api/common";
import CommonFormInfo from "../../components/common-form-info/CommonFormInfo";

const NewStudent = forwardRef((props: {
    inited?: (formInfo: ICommonFormInfoRes) => void
}, ref) => {

    useImperativeHandle(ref, () => ({
        ...commonformInfoRef.current
    }))

    const commonformInfoRef = useRef<typeof CommonFormInfo>()


    return <div className="new-student-page">
        <CommonFormInfo ref={commonformInfoRef} inited={props.inited} api="/students/create/info"/>
    </div>
})

export default NewStudent;
