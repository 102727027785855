import { Button, Col, Form, Row } from "antd";
import React, { useImperativeHandle, useState } from "react";
import CommonFormItem from "./CommonFormItem";
import { ICommonFormConfig } from "./types";

export interface ICommonFormProps {
  config: ICommonFormConfig;
}

const CommonForm = React.forwardRef((props: ICommonFormProps, ref) => {
  useImperativeHandle(ref, () => ({
    validateFields: () => {
      return form.validateFields();
    },
    getFormData: () => {
      return formData;
    },
    getFieldsValue: () => {
      return form.getFieldsValue();
    },
  }));

  const [form] = Form.useForm();
  const { items } = props.config;

  const [formData, setFormData] = useState<Record<string, any>>({});

  const handleFormItemChange = (newuValue: string) => {};
  const handleValuesChange = (values: any) => {
    setFormData((state) => {
      return {
        ...state,
        ...values,
      };
    });
  };
  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onValuesChange={handleValuesChange}
    >
      {process.env.NODE_ENV === 'development' && JSON.stringify(formData)}
      <Row>
        {items.map((item, index) => {
          return (
            <Col span={11} key={index} offset={index % 2 === 1 ? 2 : 0}>
              <CommonFormItem
                type={item.type}
                name={item.name}
                prefix={item.label}
                display=""
                list_api={item.list_api || ''}
                transformOption={item.transformOption}
                deps={item.deps}
                width={0}
                key={item.name}
                propKey={item.name}
                filterBy={formData}
                param={""}
                list={item.list || []}
                onChange={handleFormItemChange}
                required={item.required}
              ></CommonFormItem>
            </Col>
          );
        })}
      </Row>
    </Form>
  );
});

export default CommonForm;
