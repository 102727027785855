import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./CalendarPage.module.scss";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { StateType } from "../../store";
import { ICalendarComponent, UserStateType } from "../../store/userReducer";
import { useApp } from "../../context/app-context";
import CommonCalendarInfo from "../../components/common-calendar-info/CommonCalendarInfo";

const CalendarPage: FC = () => {
  const { name } = useParams() as { name: string };
  const app = useApp();

  const [componentInfo, setComponentInfo] = useState({
    calendar_api: "",
    info_api: "",
  });

  useEffect(() => {
    const permissionMap = app.getUserPermissionMap();
    const permission = permissionMap[name];
    if (!permission) {
      window.alert("permission 缺少匹配信息:" + name);
      return;
    }
    if (!permission.component) {
      window.alert("permission 缺少component匹配信息:" + name);
      return;
    }
    const component: ICalendarComponent = permission.component as any;
    setComponentInfo({
      calendar_api: component.calendar_api,
      info_api: component.info_api,
    });
  }, []);

  //   const userState = useSelector<StateType, UserStateType>(
  //     (state) => {
  //         debugger
  //         return state.user
  //     }
  //   );
  //   debugger

  //   const menuPermission = userState.permissionMap[name];

  return (
    <div
      className={classNames({
        [styles.page]: true,
        ["common-calendar-info-page"]: true,
      })}
    >
      {componentInfo.info_api && (
        <CommonCalendarInfo
          infoApi={componentInfo.info_api}
          dataApi={componentInfo.calendar_api}
        ></CommonCalendarInfo>
      )}
    </div>
  );
};

export default CalendarPage;
