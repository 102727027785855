import { Button, Form, Tooltip } from "antd";
import classNames from "classnames";
import { ICommonFilterTableSearch } from "../../api/types";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { SyntheticEvent, useState } from "react";

const Upload = (
  props: ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
) => {
  const className = classNames({
    "no-label": !props.prefix,
  });

  return (
    <Form.Item
      style={{
        width: props.width === 0 ? "initial" : (props.width || 200) + "px",
      }}
      rules={[
        {
          required: props.required,
        },
      ]}
      label={props.prefix}
      className={className}
      name={props.propKey}
    >
      <Inner {...props}></Inner>
    </Form.Item>
  );
};

const Inner = (
  props: ICommonFilterTableSearch & {
    filterBy: Record<string, any>;
  }
) => {
  //   const files: Array<{
  //     name: string;
  //     file: File;
  //   }> = [];
  const [files, setFiles] = useState<
    Array<{
      name: string;
      file: File;
    }>
  >([]);
  const handleChange = (e: any) => {
    const currentFiles = Array.prototype.slice.call(e.target.files || []);
    const newFiles = [
      ...files,
      ...currentFiles.map((file: any) => {
        return {
          name: file.name,
          file: file,
        };
      }),
    ];
    setFiles(newFiles);
    if (props.onChange) {
      props.onChange(newFiles.map((item) => item.file));
    }
  };
  const handleDelete = (index: number) => {
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (i !== index) {
        newFiles.push(files[i]);
      }
    }
    // 需要把formFiles也删除的
    setFiles(newFiles);
    if (props.onChange) {
      props.onChange(newFiles.map((item) => item.file));
    }
  };
  return (
    <div className="common-form-upload-item">
      <Button type="default" className="common-form-upload-item__button">
        <PlusOutlined /> {props.display}
        <input
          type={"file"}
          onChange={handleChange}
          name={props.propKey}
          multiple
        />
      </Button>
      <div className="upload-list">
        <div className="upload-list-item-container">
          {files.map((file, index) => {
            return (
              <div className="upload-list-item" key={index}>
                <Tooltip title={file.name}>
                    <div className="upload-list-item-name">{file.name}</div>
                </Tooltip>
                <span className="upload-list-item-actions">
                  <Button type="link" danger>
                    <DeleteOutlined onClick={() => handleDelete(index)} />
                  </Button>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Upload;
