import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import { ReactNode } from "react";
export interface IDialog {
  id?: string;
  title: string;
  onOk?: Function;
  content?: ReactNode;
  _status?: boolean;
  width?: string | number;
  height?: string | number;
  hideFooter?: boolean;
}

export type DialogStateType = {
  dialogs: Array<IDialog>;
};

export const initDialogState: DialogStateType = {
  dialogs: [],
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState: initDialogState,
  reducers: {
    updateDialog: produce((draft: DialogStateType, action: PayloadAction<Partial<IDialog>>) => {
      if (!action.payload.id) {
        return
      }
      const currentIndex = draft.dialogs.findIndex(dialog => dialog.id === action.payload.id)
      if (currentIndex > -1) {
        const oldDialog = draft.dialogs[currentIndex]
        draft.dialogs[currentIndex] = {
          ...oldDialog,
          ...action.payload
        }
      }
    }),
    openDialog: produce(
      (draft: DialogStateType, action: PayloadAction<IDialog>) => {
        const dialog = action.payload;
        draft.dialogs.push(dialog);
      }
    ),

    closeDialog: produce(
      (draft: DialogStateType, action: PayloadAction<string>) => {
        if (!action.payload) {
          draft.dialogs.pop();
        } else {
          const dialogIndex = draft.dialogs.findIndex(
            (item) => item.id === action.payload
          );
          if (dialogIndex > -1) {
            draft.dialogs.splice(dialogIndex, 1);
          }
        }
      }
    ),
    closeAllDialog: produce(
      (draft: DialogStateType) => {
        draft.dialogs.length = 0
      }
    )
    // openDialog: (state: DialogStateType, action: PayloadAction<IDialog>) => {
    //   const dialog = action.payload;
    //   dialog._status = true;
    //   if (!dialog.id) {
    //     dialog.id = nanoid();
    //   }
    //   return {
    //     ...state,
    //     dialogs: [...state.dialogs, dialog],
    //   };
    // },
    // closeDialog: (state: DialogStateType, action: PayloadAction<string>) => {
    //   if (!action.payload) {
    //     return {
    //       ...state,
    //       dialogs: state.dialogs.slice(0, state.dialogs.length - 1),
    //     };
    //   } else {
    //     const dialogIndex = state.dialogs.findIndex(
    //       (item) => item.id === action.payload
    //     );
    //     if (dialogIndex > -1) {
    //       return {
    //         ...state,
    //         dialogs: [
    //           ...state.dialogs.slice(0, dialogIndex - 1),
    //           ...state.dialogs.slice(dialogIndex + 1),
    //         ],
    //       };
    //     }
    //     return {
    //       ...state,
    //     };
    //   }
    // },
  },
});

export const { openDialog, closeDialog, closeAllDialog, updateDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
