// import cn from '../languages/cn'
// import en from '../languages/en'
import i18n, {reloadResources} from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import {getUser} from "./user";
import {request} from "./request";


export const defaultLang = 'cn'

export const locales: Record<string, any> = {};

export const initI18n = async () => {
    const lang = (getUser()?.lang || 'cn').toLowerCase()
    // const locationPath = `${window.location.origin}${window.location.pathname}`
    const locationPath = `${window.location.origin}`

    i18n.use(Backend).use(initReactI18next).init({
        fallbackLng: defaultLang,
        debug: process.env.NODE_ENV !== 'development',
        lng: lang,
        backend: {
            loadPath: function (lngs: Array<string>, namespaces: Array<string>) {
                console.log(lngs, namespaces)
                return `${locationPath}/languages/${lngs[0]}.json`;
            }
        },
        // resources: locales,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    }, (error: Error) => {
    })
}


export default i18n;