import { Button, Form, Input, Space } from "antd";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonSelect from "../../../components/common-select/CommonSelect";
import "./SignupSearch.scss";
import { newStudentGradeOptions } from "./statics";

export interface ISignupSearchProp {
  onSearch: (filterBy: {
    student_id: string;
    qr_code: string;
    new_student_grade: string;
  }) => any;
  onClear: () => any;
  onRegisterNewStudent: () => any;
}

const SignupSearch = React.forwardRef((props: ISignupSearchProp, ref) => {
  useImperativeHandle(ref, () => ({
    reloadSelectStudent: async (student_id: string) => {
      setTriggerSelectStudentShow(false);
      if (student_id) {
        setFilterBy((filter) => {
          return {
            ...filter,
            student_id,
          };
        });
      }
      requestAnimationFrame(() => {
        setTimeout(() => {
          setTriggerSelectStudentShow(true);
          if (student_id) {
            setTimeout(() => {
              form.setFieldValue("student_id", student_id);
              props.onSearch({
                student_id,
                qr_code: filterBy.qr_code,
                new_student_grade: filterBy.new_student_grade,
              });
            }, 1000);
          }
        });
      });
    },
  }));

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [filterBy, setFilterBy] = useState<Record<string, any>>({});
  const [triggerSelectStudentShow, setTriggerSelectStudentShow] =
    useState(true);
  const onValuesChange = (values: any) => {
    const fixedValues: Record<string, any> = {};
    for (let key in values) {
      fixedValues[key] = values[key] === null ? undefined : values[key];
    }
    setFilterBy((state) => {
      return {
        ...state,
        ...fixedValues,
      };
    });
  };
  const handleSearch = async (values: any) => {
    props.onSearch && props.onSearch(filterBy as any);
  };

  const handleClear = () => {
    setFilterBy({});
    form.resetFields();
    props.onClear && props.onClear();
  };

  const handletRegiserNewStudent = () => {
    props.onRegisterNewStudent && props.onRegisterNewStudent();
  };

  return (
    <Form
      className={"signup-page-filter"}
      layout="inline"
      form={form}
      labelWrap={true}
      onValuesChange={onValuesChange}
      onFinish={handleSearch}
    >
      <Space wrap>
        <Form.Item label="Select Student" name="student_id">
          {triggerSelectStudentShow && (
            <CommonSelect
              placeholder="Please Select"
              className={"student-select"}
              api="/students/list"
              showSearch={true}
              defaultValue={filterBy.student_id}
              valueKey="student_id"
              labelKey="student_name"
            ></CommonSelect>
          )}
        </Form.Item>
        <Form.Item label="UserId" name={"qr_code"}>
          <Input placeholder="Please Enter"></Input>
        </Form.Item>
        <Form.Item label="New Student" name="new_student_grade">
          <CommonSelect
            placeholder="Please Select"
            className={"student-grade-select"}
            options={newStudentGradeOptions}
          ></CommonSelect>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!filterBy.student_id && !filterBy.qr_code}
        >
          Search
        </Button>
        <Button type="primary" onClick={handleClear}>
          Clear
        </Button>
        <Button type="primary" onClick={handletRegiserNewStudent}>
          Register New Student
        </Button>
      </Space>
    </Form>
  );
});

export default SignupSearch;
