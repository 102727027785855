import {
  ICommonFormConfig,
  ICommonFormItemType,
} from "../../../components/common-form/types";

export const markAppointmentFormConfig: ICommonFormConfig = {
  items: [
    {
      label: "Date",
      name: "date",
      required: true,
      type: ICommonFormItemType.Date,
    },
    {
      label: "Remarks",
      name: "remarks",
      type: ICommonFormItemType.Input,
    },
    {
      label: "Time",
      name: "time",
      required: true,
      type: ICommonFormItemType.Time,
    },
    {
      label: "",
      name: "",
      type: ICommonFormItemType.Null,
    },
    {
      label: "School",
      name: "school_id",
      type: ICommonFormItemType.ApiList,
      required: true,
      list_api: "/schools/list",
      transformOption: {
        value: "school_id",
        label: "school_name",
      },
    },
    {
      label: "",
      name: "",
      type: ICommonFormItemType.Null,
    },
    {
      label: "Course",
      name: "course_master_id",
      type: ICommonFormItemType.ApiList,
      required: true,
      list_api: "/courses/master/list?need_pretest=true&school_id={school_id}",
      deps: ["school_id"],
      transformOption: {
        value: "course_master_id",
        label: "course_master_name",
      },
    },
    {
      label: "",
      name: "",
      type: ICommonFormItemType.Null,
    },
    {
      label: "Name",
      name: "name",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "",
      name: "",
      type: ICommonFormItemType.Null,
    },
    {
      label: "Age",
      name: "age",
      required: true,
      type: ICommonFormItemType.Input,
    },
    {
      label: "",
      name: "",
      type: ICommonFormItemType.Null,
    },
    {
      label: "Mobile",
      name: "mobile",
      required: true,
      type: ICommonFormItemType.Input,
    },
  ],
};
