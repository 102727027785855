import { request } from "../utils/request";
import { AxiosResponse } from "axios";
import {
  IAssignInfoRes,
  ICommonFilterTablePaginationApi,
  ICommonFilterTablePaginationReq,
  ICommonFilterTableSearchApi,
} from "./types";

export const getUserMenuApi: ICommonFilterTableSearchApi = () => {
  return request.get("/users/pagination/menu");
};

export const getUsersByPaginationApi: ICommonFilterTablePaginationApi = (
  params: ICommonFilterTablePaginationReq
) => {
  return request.post("/users/pagination", params);
};
