import { lazy } from "react";
import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import MainLayout from "../layouts/MainLayout/MainLayout";
import RootLayout from "../layouts/RootLayout";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import NotFound from "../pages/NotFound";
import { getToken } from "../utils/token";
import { checkPermission } from "../utils/user";
import { IRouterMenu, menuMap, menus } from "./menu";
import { loopMenus } from "./utils";

// 路由鑒權組件
const Appraisal = ({ children, name }: any) => {
  const token = getToken();
  if (!token) {
    return <Navigate to="/login"></Navigate>;
  } else {
    let hasPermision = checkPermission(name);
    if (!hasPermision) {
      if (process.env.NODE_ENV === "development") {
        return children;
      }
      // 無權限
      return null;
    }
    return children;
  }
};
const createRouterFromMenus = (): Array<IRouterMenu> => {
  let routes: IRouterMenu[] = [];
  for (let key in menuMap) {
    routes.push(menuMap[key]);
  }
  return routes;
};

// @Deprecated
// const createRouterFromMenus2 = (): Array<RouteObject> => {
//   let res: RouteObject[] = []
//   loopMenus<IRouterMenu & {
//     fullPath?: string
//   }>((item, parent) => {
//     item.fullPath = parent ? `${parent.fullPath}/${item.path}` : item.path
//     if (item.fullPath.endsWith('/')) {
//       item.fullPath = item.fullPath.slice(0, -1)
//     }
//     if (item.element) {
//       res.push({
//         path: item.fullPath || item.path,
//         element: <Appraisal name={item.key}>{item.element}</Appraisal>,
//       })
//     }
//     ;(item as any).meta = {
//       title: item.title,
//       fullPath: item.fullPath,
//       key: item.key
//     }
//   }, menus)
//   return res;
// }

// 快速導入工具函數

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <MainLayout />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
          ...createRouterFromMenus(),
        ],
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

// export const defaultRoutes = [
//   {
//     path: "/",
//     element: <RootLayout />,
//     children: [
//       {
//         path: "/",
//         element: <MainLayout />,
//         children: [
//           {
//             path: "/",
//             element: <Home />,
//           },
//         ],
//       },
//       {
//         path: "/login",
//         element: <Login />,
//       },
//       {
//         path: "*",
//         element: <NotFound />,
//       },
//     ],
//   },
// ];

export default router;
