import { configureStore } from "@reduxjs/toolkit";
import menuReducer, { MenuStateType } from "./menuReducer";
import dialogReducer, { DialogStateType } from "./dialogReducer";
// import userReducer, { UserStateType } from "./userReducer";
export type StateType = {
  menu: MenuStateType;
  dialog: DialogStateType;
  // user: UserStateType;
};

export default configureStore({
  reducer: {
    menu: menuReducer,
    dialog: dialogReducer,
    // user: userReducer,
  },
});
