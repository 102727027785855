import { Spin } from "antd";
import { useEffect, useState } from "react";
import { loadingPublisher } from "../../utils/loading";
import "../loading/Loading.scss";

export const GlobalLoading = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadingPublisher.subscribe((loadingTag) => {
      if (!loadingTag) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    });
  }, []);
  if (!loading) return null;
  return (
    <div className="global-loading-wrapper">
      <Spin spinning={loading}></Spin>
    </div>
  );
};
