import { Row } from "antd";
import React, { useImperativeHandle, useRef } from "react";
import CommonForm from "../../../components/common-form/CommonForm";

import { markAppointmentFormConfig } from "./MarkAppointmentFormConfig";

const MarkAppointment = React.forwardRef((prop: any, ref) => {
  const commonFormRef = useRef<typeof CommonForm>();

  useImperativeHandle(ref, () => ({
    getCommonFormInstance: () => {
      return commonFormRef.current;
    },
  }));

  return (
    <div className="mark-appointment">
      <CommonForm
        config={markAppointmentFormConfig}
        ref={commonFormRef}
      ></CommonForm>
    </div>
  );
});

export default MarkAppointment;
