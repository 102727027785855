import { useLocalStorageState } from "ahooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useMatch, useMatches, useNavigate, useRoutes } from "react-router";
import { StateType } from "../store";
import {
  initMenuState,
  IMenu,
  MenuStateType,
  resetMenu,
  setCurrentPath,
} from "../store/menuReducer";

export const useMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const nav = useNavigate();
  const menuState = useSelector<StateType, MenuStateType>(
    (state) => state.menu
  );
  const { menus, currentPath } = menuState;
  const [localMenuStr, setLocalMenuStr] = useLocalStorageState<
    string | undefined
  >("menus", {
    defaultValue: JSON.stringify(initMenuState.menus),
  });

  useEffect(() => {
    setLocalMenuStr(JSON.stringify(menus));
  }, [menus]);

  // useEffect(() => {
  //   if (location.pathname !== currentPath) {
  //     dispatch(setCurrentPath(location.pathname));
  //   }
  // }, [location.pathname]);

  

  // mount
  useEffect(() => {
    dispatch(setCurrentPath(location.pathname));
    if (localMenuStr) {
      try {
        const localMenus: Array<IMenu> = JSON.parse(localMenuStr);
        if (localMenuStr && Array.isArray(localMenus)) {
            dispatch(resetMenu(localMenus));
        }
      } catch {}
    } else {
        dispatch(resetMenu([]))
    }
  }, []);

  useEffect(() => {
    if (currentPath === '') {
      // 初始化
      return
    }
    if (location.pathname !== currentPath) {
      nav(currentPath)
    }
  }, [currentPath])

  return {
    menus,
  };
};
