import { FC, useEffect } from "react";
import { Layout } from "antd";
import styles from "./MainLayout.module.scss";
import SideMenu from "./SideMenu";
import Header from "./Header";
import MainContent from './MainContent';
import Tags from "./Tags";
import { useLocation } from "react-router";
import { useBeforeUnload } from "react-router-dom";

const MainLayout: FC = () => {

  

  // useEffect(() => {
  //   return () => {
  //     setTimeout(() => {
  //       if (window.location.pathname.startsWith('/login')) {
  //         window.location.reload()
  //       }
  //     }, 100)
  //   }
  // }, [])

  
  return (
    <Layout className={styles.layout}>
      <SideMenu></SideMenu>
      <Layout>
        <Header />
        <Tags />
        <MainContent/>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
