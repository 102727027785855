import { request } from "../utils/request";
import { AxiosResponse } from "axios";
import {
  IAssignInfoRes,
  ICommonFilterOperatorComponentCrudType,
  ICommonFilterTablePaginationApi,
  ICommonFilterTablePaginationReq,
  ICommonFilterTableSearchApi,
  ICommonFormInfoItem,
} from "./types";
import { getToken } from "../utils/token";
import { getLang } from "../utils/user";

export interface ICommonOption {
  id: string;
  name: string;
}

export const getCommonOptionsByApi = (
  api: string,
  params?: Record<string, any>
): Promise<AxiosResponse<Array<ICommonOption>>> => {
  if (params) {
    for (let key in params) {
      let reg = new RegExp(`\{${key}\}`);
      api = api.replace(reg, params[key]);
    }
  }
  return request.get(api);
};

export const getCommonAssignApi = (
  api: string,
  target_id: string
): Promise<AxiosResponse<IAssignInfoRes>> => {
  return request.get(api, {
    params: {
      target_id: target_id,
    },
  });
};

export const postCommonAssignApi = (
  api: string,
  target_id: string,
  assigned_list: Array<string>
): Promise<
  AxiosResponse<{
    detail: string;
  }>
> => {
  return request.post(api, {
    target_id,
    assigned_list,
  });
};

export const getCommonFilterTableMenuApi: ICommonFilterTableSearchApi = (
  api?: string
) => {
  return request.get(api || "");
};

export const getCommonFilterTableByPaginationApi: ICommonFilterTablePaginationApi =
  (params: ICommonFilterTablePaginationReq, api?: string) => {
    return request.post(api || "", params);
  };

export interface IComonCalendarInfoRes {
  prefix: string;
  default_target_name: string;
  default_target_id: string;
  show_searcher: boolean;
  searcher_prefix: string;
  searcher_display: string;
  default_calendar_type: string;
  targets: Array<{
    target_name: string;
    target_id: string;
  }>;
}

export const getCommonCalendarInfoApi = (
  api: string
): Promise<AxiosResponse<IComonCalendarInfoRes>> => {
  return request.get(api);
};
export interface ICommonCalendarDataParam {
  calendar_type: string;
  year_month: string;
  target_id: string;
  searcher: string;
}

export interface ICommonCalendarDataRes {
  target_name: string;
  target_id: string;
  calendars: Array<{
    date: string;
    is_holiday: boolean;
    events: ICommonCalendarEventRes[];
  }>;
}

export interface ICommonCalendarEventCrudComponentType
  extends ICommonFilterOperatorComponentCrudType {
  body: {
    pagination_ids: string[];
  };
}

export interface ICommonCalendarEventRes {
  color: string;
  component: null | ICommonCalendarEventCrudComponentType;
  title: string;
}

export const getCommonCalendarDataApi = (
  api: string,
  params: ICommonCalendarDataParam
): Promise<AxiosResponse<ICommonCalendarDataRes>> => {
  return request.get(api, {
    params,
  });
};

export interface ICommonFormInfoRes {
  title: string;
  info?: Array<{
    title: string;
    content: string;
  }>;
  required_fields: Array<ICommonFormInfoItem>;
  optional_fields: Array<ICommonFormInfoItem>;
  api: string;
  heading: string;
  method: string;
  default_params: Record<string, any>;
}
export const getCommonFormInfoApi = (
  api: string,
  params?: Record<string, any>
): Promise<AxiosResponse<ICommonFormInfoRes>> => {
  return request.post(api, params);
};

export const updateCommonFormInfoApi = (
  api: string,
  methodType: string,
  formData: Record<string, any>
): Promise<
  AxiosResponse<{
    detail: string;
  }>
> => {
  const form = new FormData();
  for (let key in formData) {
    // 判断是否是数组，且里面是不是File对象
    const value = formData[key];
    if (value && Array.isArray(value) && value[0] instanceof File) {
      value.forEach((file) => {
        form.append(key, file);
      });
    } else {
      form.append(key, value);
    }
  }
  const token = getToken();
  const headers: any = {
    "Content-Type": "multipart/form-data",
  };
  if (token) {
    headers["X-Token"] = token;
  }
  const lang = getLang();
  headers["lang"] = lang || "cn";
  return (request as any)
    [methodType](api, form, {
      headers,
    })
    .then((res: AxiosResponse) => {
      const contentDisposition = res.headers["content-disposition"] as string;
      if (contentDisposition) {
        const filename = contentDisposition.replace(
          "attachment; filename=",
          ""
        );
        const blob = new Blob([res.data]);
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.target = "_blank";
        // a.dispatchEvent(new UIEvent('click'))
        a.click();
        res.data = {
          detail: "Success",
        };
      }
      return res;
    });
};
