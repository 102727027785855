import { Form, Input, InputNumber } from "antd";
import classNames from "classnames";
import { FC } from "react";
import { ICommonFilterTableSearch } from "../../api/types";

const Null: FC<ICommonFilterTableSearch> = (
  props: ICommonFilterTableSearch
) => {
  const className = classNames({
    "no-label": true,
  });
  return (
    <Form.Item
      style={{ width: (props.width || 200) + "px" }}
      className={className}
    ></Form.Item>
  );
};

export default Null;
