import { message } from "antd";
import classNames from "classnames";
import { FC, Key, Ref, useRef } from "react";
import { getRoleMenuApi, getRolesByPaginationApi } from "../../api/role";
import {
  getTeachersByPaginationApi,
  getTeachersMenuApi,
} from "../../api/teacher";
import { getUserMenuApi, getUsersByPaginationApi } from "../../api/user";
import CommonFilterTablePagination, {
  ITableSelectedData,
} from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import { useDialog } from "../../hooks/useDialog";
import Allocation from "../Allocation/Allocation";
import styles from "./Roles.module.scss";

const Roles: FC = () => {
  const { dialogs, openDialog, closeDialog } = useDialog();

  const allocationRef = useRef<any>()

  const operationFuncs = {
    RoleAssignUser: (tableData: ITableSelectedData) => {
        openDialog({
            title: "Assign User",
            width: 900,
            content: (
              <Allocation
                ref={allocationRef}
                api="/roles/users/assign/info"
                target_id={tableData.selected[0] as string}
              />
            ),
            async onOk(done: Function) {
                if (allocationRef.current) {
                    try {
                        const res = await allocationRef.current.submit()
                        done();
                        message.success(res.data.detail)
                        tableData.refreshList()
                    } catch (error) {
                        message.error('error!')
                    }
                }
            },
          });
    },
    RoleAssignAuthority: (tableData: ITableSelectedData) => {
      openDialog({
          title: "Assign Authority",
          width: 900,
          content: (
            <Allocation
              ref={allocationRef}
              api="/roles/authorities/assign/info"
              target_id={tableData.selected[0] as string}
            />
          ),
          async onOk(done: Function) {
              if (allocationRef.current) {
                  try {
                      const res = await allocationRef.current.submit()
                      done();
                      message.success(res.data.detail)
                      tableData.refreshList()
                  } catch (error) {
                      message.error('error!')
                  }
              }
          },
        });
  }
  };

  return (
    <>
      <div  className={classNames({
        [styles.page]:true,
        ['common-filter-table-pagination-page']: true
      })}>
        <CommonFilterTablePagination
          getSearchPromise={getRoleMenuApi}
          getListPromise={getRolesByPaginationApi}
          rowKey="role_id"
          operationFuncs={operationFuncs}
          // operation={<FilterOperation />}
        ></CommonFilterTablePagination>
      </div>
    </>
  );
};

export default Roles;
