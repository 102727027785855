import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import {
  Layout,
  Row,
  Select,
  Space,
  Button,
  Card,
  Input,
  List,
  Checkbox,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { AxiosResponse } from "axios";
import { cloneDeep } from "lodash-es";
import {
  ChangeEvent,
  ChangeEventHandler,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { getCommonAssignApi, postCommonAssignApi } from "../../api/common";
import { IAssignInfoRes } from "../../api/types";
import styles from "./Allocation.module.scss";

const CardTitle = (props: {
  placeholder?: string;
  onChange: (newVal: string) => void;
}) => {
  const handleChange: ChangeEventHandler = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    props.onChange(e.target.value);
  };
  return <Input placeholder={props.placeholder} onChange={handleChange} />;
};

interface IListItemProp {
  isTitle?: boolean;
  footer?: ReactNode;
  checked?: boolean;
  value: string;
  label: string;
  indeterminate?: boolean;
  onChange: (newValue: boolean, item: IListItemProp) => void;
}
const ListItem = (props: IListItemProp) => {
  const onChange = (e: CheckboxChangeEvent) => {
    props.onChange(e.target.checked, props);
  };
  return (
    <Space className={styles.listItem}>
      <Checkbox
        indeterminate={props.indeterminate}
        checked={props.checked}
        onChange={onChange}
      >
        {props.label}
      </Checkbox>
      {props.footer}
      {/* <span className={styles.tips}>1/7</span> */}
    </Space>
  );
};

export interface ISearchDataSourceItem extends Record<string, any> {
  label: string;
  value: string;
}

export interface ISearchListProp {
  datasource: Array<ISearchDataSourceItem>;
  title: string;
  placeholder: string;
  onChange: (
    values: Array<string>,
    newDataSource: Array<ISearchDataSourceItem>
  ) => void;
}

const SearchList = (props: ISearchListProp) => {
  const [keyword, setKeyword] = useState("");
  const [checkedMap, setCheckedMap] = useState<Record<string, boolean>>({});

  const handleToggleCheckAll = (checked: boolean) => {
    let newCheckeds: Record<string, boolean> = {};
    if (checked) {
      newCheckeds = props.datasource.reduce((prev, current) => {
        prev[current.value] = true;
        return prev;
      }, {} as Record<string, boolean>);
    }
    setCheckedMap(newCheckeds);
    const newDatasources = checked ? cloneDeep(props.datasource) : [];
    props.onChange(Object.keys(newCheckeds), newDatasources);
  };
  const handleToggleCheck = (checkbox: boolean, item: IListItemProp) => {
    const newCheckeds = {
      ...checkedMap,
      [item.value]: checkbox,
    };
    setCheckedMap(newCheckeds);
    const newDatasources = props.datasource.filter((item) => {
      return newCheckeds[item.value];
    });
    props.onChange(
      Object.keys(newCheckeds).filter((item) => !!item),
      newDatasources
    );
  };
  const handleInputChange = (newVal: string) => {
    setKeyword(newVal);
  };
  const filteredDatasource = props.datasource.filter((item) =>
    item.label.toLowerCase().includes(keyword.toLowerCase())
  );

  return (
    <Card
      title={
        <CardTitle
          placeholder={props.placeholder || ""}
          onChange={handleInputChange}
        />
      }
    >
      <List
        dataSource={filteredDatasource}
        bordered
        rowKey={"value"}
        header={
          <ListItem
            isTitle={true}
            label={props.title}
            value=""
            checked={
              Object.keys(checkedMap).length === filteredDatasource.length
            }
            indeterminate={
              Object.keys(checkedMap).length > 0 &&
              Object.keys(checkedMap).length !== filteredDatasource.length
            }
            onChange={handleToggleCheckAll}
            footer={
              <span className={styles.tips}>
                {Object.keys(checkedMap).length}/{props.datasource.length}
              </span>
            }
          />
        }
        renderItem={(item: ISearchDataSourceItem) => {
          return (
            <ListItem
              isTitle={false}
              label={item.label}
              value={item.value}
              checked={checkedMap[item.value]}
              onChange={handleToggleCheck}
            />
          );
        }}
      ></List>
    </Card>
  );
};

export interface IAllocationRef {
  assigned_list: IAssignInfoRes["unassigned_list"];
  unassigned_list: IAssignInfoRes["assigned_list"];
  submit: () => Promise<
    AxiosResponse<{
      detail: string;
    }>
  >;
}

export interface IAllocationProp {
  api: string;
  target_id: string;
}
const Allocation = forwardRef<IAllocationRef, IAllocationProp>(
  (props: IAllocationProp, childRef) => {
    const targetDataRef = useRef<IAssignInfoRes>({
      api: "",
      assigned_list: [],
      assigned_title: "",
      default_target_id: "",
      prefix: "",
      search_assigned_display: "",
      search_unassigned_display: "",
      target_id: "",
      target_name: "",
      targets: [],
      title: "",
      unassigned_list: [],
      unassigned_title: "",
    });

    // const [tmpUnassignedCheckeds, setTmpUnassignedCheckeds] = useState<
    //   Array<string>
    // >([]);
    // const [tmpAssignedCheckeds, setTmpAssignedCheckeds] = useState<Array<string>>(
    //   []
    // );
    const [unAssignedCheckedList, setUnAssignedCheckedList] = useState<
      IAssignInfoRes["unassigned_list"]
    >([]);
    const [assignedCheckedList, setAssignedCheckedList] = useState<
      IAssignInfoRes["assigned_list"]
    >([]);
    const [searchValue, setSearchValue] = useState("");

    useImperativeHandle(childRef, () => {
      return {
        assigned_list: targetDataRef.current.assigned_list,
        unassigned_list: targetDataRef.current.unassigned_list,
        submit: async () => {
          debugger;
          let res = await postCommonAssignApi(
            targetDataRef.current.api,
            targetDataRef.current.target_id,
            targetDataRef.current.assigned_list.map((item) => item.id)
          );
          return res;
        },
      };
    });

    const { data, run } = useRequest(
      async (target_id: string) => {
        //   return await getCourseAssignInfo("dfc3aa3b-df18-11eb-ac36-02f43361ec14");
        return await getCommonAssignApi(props.api, target_id);
      },
      {
        manual: true,
        onSuccess(data) {
          // setTargetData(data.data);
          targetDataRef.current = data.data;
          setUnAssignedCheckedList(data.data.unassigned_list);
          setAssignedCheckedList(data.data.assigned_list);
          // setTmpUnassignedCheckeds(
          //   data.data.unassigned_list.map((item) => item.id)
          // );
          // setTmpAssignedCheckeds(data.data.assigned_list.map((item) => item.id));
        },
      }
    );

    const handleTargetChange = (newVal: string) => {
      // setTargetData((targetData) => {
      //   return {
      //     ...targetData,
      //     target_id: newVal,
      //   };
      // });
      targetDataRef.current = {
        ...targetDataRef.current,
        target_id: newVal,
      };
      run(newVal);
    };

    const handleTargetSearch = (value: string) => {
      setSearchValue(value);
    };

    const handleFilterOption: any = (
      inputValue: string,
      option: Record<string, string>
    ) => {
      if (!inputValue) return;
      return option.target_name
        .toLowerCase()
        .includes(inputValue.toLowerCase());
    };
    const handleUnsignChange = (
      values: Array<string>,
      newDataSource: Array<ISearchDataSourceItem>
    ) => {
      // setTmpUnassignedCheckeds(values);
      setUnAssignedCheckedList(
        newDataSource.map((item) => {
          return {
            id: item.value,
            name: item.label,
          };
        })
      );
    };

    const handlAssignedChange = (
      values: Array<string>,
      newDataSource: Array<ISearchDataSourceItem>
    ) => {
      // setTmpAssignedCheckeds(values);
      setAssignedCheckedList(
        newDataSource.map((item) => {
          return {
            id: item.value,
            name: item.label,
          };
        })
      );
    };

    const handleConfirmUnassignedToAssigned = () => {
      // setTargetData((state) => {
      //   return {
      //     ...state,
      //     assigned_list: [...state.assigned_list, ...unAssignedCheckedList],
      //     unassigned_list: [...state.unassigned_list].filter((item) => {
      //       return !unAssignedCheckedList.find(
      //         (unAssigned) => unAssigned.id === item.id
      //       );
      //     }),
      //   };
      // });
      targetDataRef.current = {
        ...targetDataRef.current,
        assigned_list: [
          ...targetDataRef.current.assigned_list,
          ...unAssignedCheckedList,
        ],
        unassigned_list: [...targetDataRef.current.unassigned_list].filter(
          (item) => {
            return !unAssignedCheckedList.find(
              (unAssigned) => unAssigned.id === item.id
            );
          }
        ),
      };
      setUnAssignedCheckedList([]);
    };

    const handleConfirmAssignedToUnassigned = () => {
      // setTargetData((state) => {
      //   return {
      //     ...state,
      //     unassigned_list: [...state.unassigned_list, ...assignedCheckedList],
      //     assigned_list: [...state.assigned_list].filter((item) => {
      //       return !assignedCheckedList.find(
      //         (assigned) => assigned.id === item.id
      //       );
      //     }),
      //   };
      // });
      targetDataRef.current = {
        ...targetDataRef.current,
        unassigned_list: [...targetDataRef.current.unassigned_list, ...assignedCheckedList],
        assigned_list: [...targetDataRef.current.assigned_list].filter((item) => {
          return !assignedCheckedList.find(
            (assigned) => assigned.id === item.id
          );
        }),
      };
      setAssignedCheckedList([]);
    };

    useEffect(() => {
      run(props.target_id);
    }, []);

    return (
      <Space direction="vertical" className={styles.page}>
        <Space>
          <span>{targetDataRef.current.prefix} </span>
          {searchValue}
          <Select
            placeholder="Please Select"
            style={{ width: "380px" }}
            showSearch={true}
            filterOption={handleFilterOption}
            // searchValue={searchValue}
            options={targetDataRef.current.targets.map((target) => ({
              ...target,
              label: target.target_name,
              value: target.target_id,
            }))}
            value={targetDataRef.current.target_id}
            onChange={handleTargetChange}
          ></Select>
        </Space>
        <div className={styles.content}>
          <div className={styles.left}>
            <SearchList
              title={targetDataRef.current.unassigned_title}
              placeholder={targetDataRef.current.search_unassigned_display}
              datasource={targetDataRef.current.unassigned_list.map((item) => ({
                ...item,
                label: item.name,
                value: item.id,
              }))}
              onChange={handleUnsignChange}
            />
          </div>
          <div className={styles.middle}>
            <Space direction="vertical">
              <Button
                disabled={!unAssignedCheckedList.length}
                onClick={handleConfirmUnassignedToAssigned}
              >
                <DoubleRightOutlined />
              </Button>
              <Button
                disabled={!assignedCheckedList.length}
                onClick={handleConfirmAssignedToUnassigned}
              >
                <DoubleLeftOutlined />
              </Button>
            </Space>
          </div>
          <div className={styles.right}>
            <SearchList
              title={targetDataRef.current.assigned_title}
              placeholder={targetDataRef.current.search_assigned_display}
              datasource={targetDataRef.current.assigned_list.map((item) => ({
                ...item,
                label: item.name,
                value: item.id,
              }))}
              onChange={handlAssignedChange}
            />
          </div>
        </div>
      </Space>
    );
  }
);
export default Allocation;
