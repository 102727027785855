import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { dialogOptionMap, useDialog } from "../../hooks/useDialog";

const Dialogs = () => {
  const { dialogs, closeDialog } = useDialog();
  return (
    <>
      {dialogs.map((dialog) => {
        const handleOk = async () => {
          const okFn = dialogOptionMap[dialog.id as string]?.onOk;
          if (okFn) {
            const done = () => {
              closeDialog(dialog.id);
            };
            await okFn(done);
          }
        };
        const handleCancel = () => {
          closeDialog(dialog.id);
        };
        let content = dialogOptionMap[dialog.id as string]?.content
        return (
          <Modal
            key={dialog.id}
            title={dialog.title}
            open={dialog._status}
            width={dialog.width || 780}
            maskClosable={false}
            destroyOnClose={true}
            footer={dialog.hideFooter === true ? null : undefined}
            bodyStyle={{
                height: dialog.height || 'calc(100vh -  220px)',
                overflow: 'auto',
            }}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            {content}
          </Modal>
        );
      })}
    </>
  );
};

export default Dialogs;
