import { message } from "antd";
import classNames from "classnames";
import { FC, Key, Ref, useRef } from "react";
import {
  getTeachersByPaginationApi,
  getTeachersMenuApi,
} from "../../api/teacher";
import { getUserMenuApi, getUsersByPaginationApi } from "../../api/user";
import CommonFilterTablePagination, {
  ITableSelectedData,
} from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import { useDialog } from "../../hooks/useDialog";
import Allocation from "../Allocation/Allocation";
import styles from "./Users.module.scss";

const Users: FC = () => {
  const { dialogs, openDialog, closeDialog } = useDialog();

  const allocationRef = useRef<any>()

  const operationFuncs = {
    UserAssignRole: (tableData: ITableSelectedData) => {
        openDialog({
            title: "Assign Role",
            width: 900,
            content: (
              <Allocation
                ref={allocationRef}
                api="/users/roles/assign/info"
                target_id={tableData.selected[0] as string}
              />
            ),
            async onOk(done: Function) {
                if (allocationRef.current) {
                    try {
                        const res = await allocationRef.current.submit()
                        done();
                        message.success(res.data.detail)
                        tableData.refreshList()
                    } catch (error) {
                        message.error('error!')
                    }
                }
            },
          });
    }
  };

  return (
    <>
      <div  className={classNames({
        [styles.page]:true,
        ['common-filter-table-pagination-page']: true
      })}>
        <CommonFilterTablePagination
          getSearchPromise={getUserMenuApi}
          getListPromise={getUsersByPaginationApi}
          rowKey="user_id"
          operationFuncs={operationFuncs}
          // operation={<FilterOperation />}
        ></CommonFilterTablePagination>
      </div>
    </>
  );
};

export default Users;
