import React, { ReactNode, useEffect, useRef, useState } from "react";
import { message } from "antd";
import { getUser as getStorageUser, IUser, setUser as setStorageUser } from "../utils/user";
import { IPermission } from "../store/userReducer";

const AppContext = React.createContext<
  | {
      loading: boolean;
      setLoading: (loading: boolean) => void;
      //   startLoading: () => void;
      //   closeLoading: () => void;
      user: IUser | undefined;
      getUser: () => IUser | undefined;
      setUser: (user: IUser | undefined) => void;
      getUserPermissionMap: () => Record<string, IPermission>
    }
  | undefined
>(undefined);
AppContext.displayName = "AppContext";

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);

  //   const handler = useRef<ToastHandler>();

  const [user, setUser] = useState<IUser | undefined>(getStorageUser() || undefined);
  // const useref = useRef<IUser | null>(getStorageUser() || null)

  // const setUser = (user: IUser | undefined) => {
  //   debugger
  //   if (!user) {
  //     useref.current = null
  //     setStorageUser(undefined)
  //   } else {
  //     useref.current = user
  //     setStorageUser(user)
  //   }
  // }


  const getUser = () => {
    // return useref.current || undefined;
    return user;
  };

  const getUserPermissionMap = () => {
    const permissions = user?.permissions || [];
    let res: Record<string, IPermission> = {};
    const setItem = (permission: IPermission) => {
      const children = permission.children;
      res[permission.name] = {
        ...permission,
        children: undefined,
      };
      if (children && children.length) {
        children.forEach(setItem);
      }
    };
    permissions.forEach((permission) => setItem(permission as any));

    return res;
  };

  return (
    <AppContext.Provider
      children={children}
      value={{
        loading,
        setLoading,
        // startLoading,
        // closeLoading,
        user,
        setUser,
        getUser,
        getUserPermissionMap
      }}
    />
  );
};

export const useApp = () => {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error("useApp 必须在AppProvider中使用");
  }
  return context;
};
