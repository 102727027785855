import { FC, MouseEvent, useEffect } from "react";
import styles from "./Tags.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { useMenu } from "../../hooks/useMenu";
import { Layout } from "antd";
import { addMenu, IMenu, removeMenu } from "../../store/menuReducer";
import { useDispatch } from "react-redux";
import { findItemFromPathName as findMenuFromPathName } from "../../router/utils";
import { nanoid } from "nanoid";

const Tags: FC = () => {
  const { menus } = useMenu();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleCloseTag = (e: MouseEvent<HTMLSpanElement>, menu: IMenu) => {
    dispatch(removeMenu(menu.id));
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    const menu = findMenuFromPathName(location.pathname);
    if (menu) {
      dispatch(
        addMenu({
          title: menu.title,
          path: menu.fullPath || menu.path,
          id: nanoid(5),
        })
      );
    }
  }, []);

  return (
    <div className={styles.tags}>
      {menus.map((menu) => {
        let isActive = location.pathname === menu.path;

        const activeItemClass = classNames({
          [styles.active]: isActive,
          [styles.tagItem]: true,
        });
        return (
          <div className={activeItemClass} key={menu.id}>
            <Link to={menu.path} className={styles.link}>
              <span className={styles.title}>{menu.title}</span>
              {menu.path !== "/" && (
                <CloseCircleOutlined
                  className={styles.close}
                  onClick={(e) => handleCloseTag(e, menu)}
                />
              )}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Tags;
