import { Form, Input, InputNumber } from "antd";
import classNames from "classnames";
import { FC } from "react";
import { ICommonFilterTableSearch } from "../../api/types";

const Int: FC<ICommonFilterTableSearch> = (props: ICommonFilterTableSearch) => {
  const className = classNames({
    "no-label": !props.prefix,
  });
  return (
    <Form.Item
      style={{
        width: props.width === 0 ? "initial" : (props.width || 200) + "px",
      }}
      label={props.prefix}
      className={className}
      name={props.propKey}
      rules={[
        {
          required: props.required,
          message: "Please Enter",
        },
      ]}
    >
      <InputNumber style={{ width: "100%" }} placeholder={props.display} />
    </Form.Item>
  );
};

export default Int;
