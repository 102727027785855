import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter, BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { initI18n } from "./utils/i18n";
import { getUser } from "./utils/user";

import enUS from "antd/es/locale/en_US";
import zhCN from "antd/es/locale/zh_CN";
import ConfigProvider from "antd/es/config-provider";
import { AppProvider } from "./context/app-context";
import { AliveScope } from "react-activation";
import { Provider } from "react-redux";
import store from "./store";
// import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';

// dayjs.locale('en');

const locales: Record<string, any> = {
  cn: zhCN,
  en: enUS,
};
const init = async () => {
  await initI18n();
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  let lang = (getUser()?.lang || "cn").toLowerCase();
  // lang = 'en'
  root.render(
    // <React.StrictMode>
      <Provider store={store}>
        <ConfigProvider locale={locales[lang]}theme={{
          token: {
            colorPrimary: '#1C85BF'
          }
        }}>
          <AppProvider>
            <AliveScope>
              <App />
            </AliveScope>
          </AppProvider>
        </ConfigProvider>
      </Provider>
   //</React.StrictMode>
  );
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
