import { FC } from "react";
import {
  getClassesByPaginationApi,
  getClassesMenuApi,
} from "../../api/classes";
import CommonFilterTablePagination from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import FilterOperation from "./FilterOperation";
import styles from "./Classes.module.scss";
import classNames from "classnames";
import { useMenuTag } from "../../hooks/useMenuTag";

const Classes: FC = () => {
  const { goRouteAndAddTag } = useMenuTag();
  
  const operationFuncs = {
    Create: () => {
      goRouteAndAddTag("/classes/new", "New Classes");
    },
  };

  return (
    <div
      className={classNames({
        [styles.page]: true,
        ["common-filter-table-pagination-page"]: true,
      })}
    >
      <CommonFilterTablePagination
        getSearchPromise={getClassesMenuApi}
        getListPromise={getClassesByPaginationApi}
        rowKey="class_id"
        operation={<FilterOperation />}
        operationFuncs={operationFuncs}
      ></CommonFilterTablePagination>
    </div>
  );
};

export default Classes;
