import { request } from "../utils/request";
import { AxiosResponse } from "axios";

export interface IWaitingInfoRes {
  waiting_info: {
    id: string;
    type: string;
    remark: string;
  };
  student_info: {
    id: string;
    name: string;
    school: string;
    grade: string;
  };
  course_info: {
    school: string;
    course: string;
    stage: string;
    sale_package: string;
    finished_lessons: number | null;
    registered_lesson: number| null;
  };
}

export const getWaitingInfoApi = (
  waiting_id: string
): Promise<AxiosResponse<IWaitingInfoRes>> => {
  return request.get("/waiting/info", {
    params: {
      waiting_id,
    },
  });
};
