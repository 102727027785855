import { message } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { ICommonFormInfoRes } from "../../api/common";
import CommonFormInfo from "../../components/common-form-info/CommonFormInfo";

const CurdPage = forwardRef((props: {
    inited?: (formInfo: ICommonFormInfoRes) => void
    info_api: string
    pagination_ids?: Array<string>
}, ref) => {

    useImperativeHandle(ref, () => ({
        ...commonformInfoRef.current
    }))

    const commonformInfoRef = useRef<typeof CommonFormInfo>()


    return <div className="common-curd-page">
        <CommonFormInfo ref={commonformInfoRef} params={{
            pagination_ids: props.pagination_ids
        }} inited={props.inited} api={props.info_api}/>
    </div>
})

export default CurdPage;
