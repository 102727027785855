import { message } from "antd";
import classNames from "classnames";
import { nanoid } from "nanoid";
import { FC, Key, Ref, useEffect, useRef } from "react";
import {
  getAuthoritiesByPaginationApi,
  getAuthorityMenuApi,
} from "../../api/authority";
import {
  getCommonFilterTableByPaginationApi,
  getCommonFilterTableMenuApi,
  ICommonFormInfoRes,
} from "../../api/common";
import { ICommonFilterTablePaginationReq } from "../../api/types";
import CommonFilterTablePagination, {
  ITableSelectedData,
} from "../../components/common-filter-table-pagination/CommonFilterTablePagination";
import { ICommonFormInfoConfig } from "../../components/common-form-info/types";
import { useDialog, useDialogRef } from "../../hooks/useDialog";
import Allocation from "../Allocation/Allocation";
import NewStudent from "./NewStudent";
import styles from "./Students.module.scss";

const Students: FC = () => {
  // const dialog = useDialog();

  // const dialogRef = useRef<any>()

  // useEffect(() => {
  //   dialogRef.current = dialog
  // }, [dialog.dialogs])

  const dialogRef = useDialogRef()

  const allocationRef = useRef<any>();

  const newStudentRef = useRef<any>();

  const operationFuncs = {
    Create: (tableData: ITableSelectedData) => {
      const dialogId = nanoid()
      dialogRef.current?.openDialog({
        id: dialogId,
        title: "Create New Student",
        content: <NewStudent ref={newStudentRef} inited={(formInfo: ICommonFormInfoRes) => {
          dialogRef.current?.updateDialog({
            id: dialogId,
            title: formInfo.heading
          })
        }}/>,
        width: 860,
        onOk: async () => {
          try {
            let data: any = await newStudentRef.current.updateInfoData();
            if (data) {
              message.success(data.detail)
              // 刷新页面
              tableData.refreshList();
              dialogRef?.current?.closeDialog()
            }
          } catch (e: any) {
            console.error(e);
            message.error(e?.response?.data?.detail || "Error");
          }
          // dialogRef.current.closeDialog()
        },
      });
    },
  };

  return (
    <>
      <div
        className={classNames({
          [styles.page]: true,
          ["common-filter-table-pagination-page"]: true,
        })}
      >
        <CommonFilterTablePagination
          getSearchPromise={() =>
            getCommonFilterTableMenuApi("/students/pagination/menu")
          }
          getListPromise={(params: ICommonFilterTablePaginationReq) =>
            getCommonFilterTableByPaginationApi(params, "/students/pagination")
          }
          rowKey="student_id"
          operationFuncs={operationFuncs}
          // operation={<FilterOperation />}
        ></CommonFilterTablePagination>
      </div>
    </>
  );
};

export default Students;
