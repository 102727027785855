import React, { useEffect, useState } from "react";

import "./App.css";
import "./App.scss";
import { Outlet, RouterProvider, useRoutes } from "react-router";
import router from "./router";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { StateType } from "./store";
import { UserStateType } from "./store/userReducer";
function App() {
  return (
    <>
      {/* <BrowserRouter>
        <Outlet></Outlet>
      </BrowserRouter> */}
      <RouterProvider router={router}></RouterProvider>
      {/* <Dialogs></Dialogs> */}
    </>
  );
}

export default App;
