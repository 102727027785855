import { IRouterMenu, menus } from "./menu";
import { cloneDeep } from "lodash-es";
import { checkPermission } from "../utils/user";

interface ITemplateRouteMenu extends IRouterMenu {
  tempKey: string;
  fullPath: string;
  children?: ITemplateRouteMenu[];
}

export const loopMenus = <T extends Record<string, any>>(
  callback: (item: T, parent?: T) => void,
  items: T[],
  parent?: T,
  forKey?: string
) => {
  items.forEach((item) => {
    callback(item, parent);
    if (item[forKey || "children"]) {
      loopMenus(callback, item[forKey || "children"], item, forKey);
    }
  });
};

export const findKeyPathFromPathName = (pathname: string) => {
  let keyPath: string[] = [];
  loopMenus((item: any, parent) => {
    if (item.path === pathname) {
      keyPath.push(item.key);
    }
  }, menus);
  return keyPath;
};

export const findItemFromPathName = (pathname: string): ITemplateRouteMenu | null => {
  let res: null | ITemplateRouteMenu = null;
  loopMenus<ITemplateRouteMenu>((item, parent) => {
    if (item.path && (item.path.toLowerCase() === pathname)) {
      res = item;
    }
  }, menus as ITemplateRouteMenu[]);
  return res;
};

export const findPathFromMenuKeyPath = (keyPath: string[]) => {
  const menusMap: Map<string, ITemplateRouteMenu> = new Map();
  const loop = (
    callback: (item: ITemplateRouteMenu) => void,
    items: ITemplateRouteMenu[],
    parent?: ITemplateRouteMenu
  ) => {
    loopMenus<ITemplateRouteMenu>(
      (item, parent) => {
        item.parent = parent;
        item.tempKey = parent ? `${parent.tempKey}:${item.key}` : item.key;
        item.fullPath = parent ? `${parent.fullPath}/${item.path}` : item.path;
        if (item.fullPath.endsWith("/")) {
          item.fullPath = item.fullPath.slice(0, -1);
        }
        callback(item);
        if (item.children) {
          loop(callback, item.children, item);
        }
      },
      items,
      parent,
      "children"
    );
  };
  const tmpMenus = cloneDeep(menus as ITemplateRouteMenu[]);

  loop((item) => {
    menusMap.set(item.tempKey, item);
  }, tmpMenus);
  const keyPathStr = keyPath.reverse().join(":");
  return menusMap.get(keyPathStr);
};

export const checkPermissionMenu = (menu: IRouterMenu) => {
  if (!menu) return false;
  if (!menu.key) return true;
  return checkPermission(menu.key as string) || true;
};

export const filterPermissionMenus = (menus: Array<IRouterMenu>) => {
  if (!menus.length) return menus;
  return menus
    .filter((menu) => {
      let hasPermission = checkPermissionMenu(menu);
      if (!hasPermission) return false;
      if (menu.hideMenu) return false
      if ((menu as any).children && (menu as any).children.length) {
        (menu as any).children = filterPermissionMenus((menu as any).children);
        if ((menu as any).children.length) {
          return true;
        } else {
          return false;
        }
      }
      return hasPermission;
    })
    .map((menu) => {
      let res = {
        ...menu,
        label: (menu as any).title,
      };
      // @ts-ignore
      delete res.fullPath;
      return res;
    });
};
