export class Loading {
  loadingTag = 0;

  subscriptions: Function[] = [];

  constructor() {
    this.loadingTag = 0;
    this.subscriptions = [];
  }

  add() {
    this.loadingTag++;
    this.subscriptions.forEach((f) => f(this.loadingTag));
  }

  sub() {
    this.loadingTag--;
    this.subscriptions.forEach((f) => f(this.loadingTag));
  }

  get() {
    return this.loadingTag;
  }

  subscribe(f: (loadingTag: number) => void) {
    this.subscriptions.push(f);
  }
}

export const loadingPublisher = new Loading()
