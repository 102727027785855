import { Form } from "antd";
import { FC } from "react";
import {
  ICommonFilterTableSearch,
  ICommonFilterTableSearchType,
} from "../../api/types";
import ApiList from "./ApiList";
import DefaultItem from "./DefaultItem";
import Int from "./Int";
import Keyword from "./Keyword";
import Date from "./Date";
import Time from "./TIme";
import TextArea from "./TextArea";
import Upload from "./Upload";

export interface IFilterItemProps extends ICommonFilterTableSearch {
  type: string;
  propKey: string;
  filterBy: Record<string, any>
}

export const TypeMap: Record<string, any> = {
  [ICommonFilterTableSearchType.Keyword]: Keyword,
  [ICommonFilterTableSearchType.Int]: Int,
  [ICommonFilterTableSearchType.EnumList]: ApiList,
  [ICommonFilterTableSearchType.ApiList]: ApiList,
  [ICommonFilterTableSearchType.Date]: Date,
  [ICommonFilterTableSearchType.Time]: Time,
  [ICommonFilterTableSearchType.Textarea]: TextArea,
  [ICommonFilterTableSearchType.Upload]: Upload,
  [ICommonFilterTableSearchType.DicList]: ApiList
};

const FilterItem: FC<IFilterItemProps> = (props: IFilterItemProps) => {
  const Component = TypeMap[props.type] || DefaultItem;
  return (
    <Component {...props}></Component>
    // <Form.Item label={props.prefix} name={props.propKey}>

    // </Form.Item>
  );
};

export default FilterItem;
