import { Col, message, Row } from "antd";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  getCommonFormInfoApi,
  ICommonFormInfoRes,
  updateCommonFormInfoApi,
} from "../../api/common";

import "./CommonFormInfo.scss";
import CommonFormWithTwoColumn, {
  ICommonFormRef,
} from "./CommonFormWithTwoColumn";

export interface ICommonFormInfoProps {
  api: string;
  inited?: (formInfo: ICommonFormInfoRes) => void;
  params?: Record<string, any>
}
const CommonFormInfo = React.forwardRef((props: ICommonFormInfoProps, ref) => {
  const [formInfo, setFormInfo] = useState<ICommonFormInfoRes>({
    title: "",
    info: undefined,
    required_fields: [],
    optional_fields: [],
    heading: "",
    api: "",
    method: "",
    default_params: {},
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        ...commonFormRef.current,
        updateInfoData,
      };
    },
    [formInfo]
  );

  const formInfoStateRef = useRef<ICommonFormInfoRes>();
  useEffect(() => {
    formInfoStateRef.current = formInfo;
  }, [formInfo]);

  const commonFormRef = useRef<typeof CommonFormWithTwoColumn>();
  // commonFormRef.current?.validateFields

  const updateInfoData = async () => {
    try {
      // if (process.env.NODE_ENV !== "development") {
      //   await (commonFormRef.current as any).validateFields();
      // }
      await (commonFormRef.current as any).validateFields();
    } catch (error) {
      return false;
    }

    let res = await updateCommonFormInfoApi(
      formInfoStateRef?.current?.api as string,
      (formInfoStateRef?.current?.method as string) || "post",
      {
        ...formInfoStateRef.current?.default_params,
        ...((commonFormRef?.current as any)?.getFormData() as any),
      }
    );
    return res.data;
  };

  const getInfoData = async () => {
    try {
      let res = await getCommonFormInfoApi(props.api, props.params);
      props.inited?.(res.data);
      setFormInfo(() => res.data);
      let defaultFormData: Record<string, any> = {};
      if (res.data.default_params) {
        // (commonFormRef.current as any).updateFormData(res.data.default_params)
        defaultFormData = {
          ...res.data.default_params,
        };
      }
      const fields = [...res.data.optional_fields, ...res.data.required_fields]
      if (fields.length) {
        fields.forEach(field => {
          if (field.default) {
            defaultFormData[field.key] = field.default
          }
        })
      }
      (commonFormRef.current as any).updateFormData(defaultFormData)
    } catch (e: any) {
      console.error(e);
      message.error(e?.response?.data?.detail || "Error");
    }
  };
  useEffect(() => {
    getInfoData();
  }, []);
  return (
    <div className="common-form-info">
      {/* formInfo: {JSON.stringify(formInfo)} */}
      <Row className="info-wrapper">
        {formInfo.title && <div className="info-title">{formInfo.title}</div>}
        {formInfo?.info &&
          formInfo?.info.map((info, index) => {
            return (
              <Col span={24} key={index}>
                <div className="info-item">
                  <div className="title">
                    <span>{info.title}</span>
                    </div>
                  <div className="content">{info.content}</div>
                </div>
              </Col>
            );
          })}
      </Row>
      <CommonFormWithTwoColumn
        ref={commonFormRef}
        config={{
          requiredItems: formInfo.required_fields,
          optionalItems: formInfo.optional_fields,
        }}
      />
    </div>
  );
});

export default CommonFormInfo;
